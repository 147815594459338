import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionToEdit } from 'src/app/core/models';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Input() options: OptionToEdit[];
  @Input() onlyOne? = false;
  selectedFilters: OptionToEdit[] = [];
  @Output() filtersChanged = new EventEmitter<OptionToEdit[]>();

  constructor() {}

  ngOnInit(): void {}

  checkIfSelected(opt: OptionToEdit) {
    return this.selectedFilters.findIndex((x) => x.value === opt.value) !== -1;
  }

  changeOption(val: boolean, opt: OptionToEdit) {
    if (this.onlyOne) {
      this.selectedFilters = [];
      if (val) {
        this.selectedFilters.push(opt);
      }
      this.filtersChanged.next(this.selectedFilters);
    } else {
      if (val) {
        this.selectedFilters.push(opt);
        this.filtersChanged.next(this.selectedFilters);
      } else {
        this.removeSelected(opt);
      }
    }
  }

  removeSelected(opt: OptionToEdit) {
    const ind = this.selectedFilters.findIndex((x) => x.value === opt.value);
    this.selectedFilters.splice(ind, 1);
    this.filtersChanged.next(this.selectedFilters);
  }
}

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status1 of IO modules.
 */
export interface IOModulesStatus1BitMaskDto {
  disabled?: boolean;
  deviceFault?: boolean;
  blinkEnabled?: boolean;
  isolatorActive?: boolean;
  on?: boolean;
  activated?: boolean;
  outputOpen?: boolean;
  outputShort?: boolean;
  powerFault?: boolean;
  inputOpen?: boolean;
  inputShoty?: boolean;
}

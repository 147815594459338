import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import {
  BasePortalApiResponseDtoGetMyProfileResponseDto,
  UserPortalService
} from '../api/userportal';
interface Permission {
  installator_view: boolean;
  building_manager_view: boolean;
  no_type: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _token: string | null;
  permissions: Permission;
  userProfile: BasePortalApiResponseDtoGetMyProfileResponseDto;

  MDYFormatList = [
    'AS',
    'CA',
    'KY',
    'FM',
    'GH',
    'GU',
    'KE',
    'MH',
    'MP',
    'PA',
    'PH',
    'PR',
    'ZA',
    'TG',
    'UM',
    'US',
    'VI'
  ];
  YMDFormatList = ['BT', 'CN', 'HU', 'JP', 'KP', 'KR', 'LT', 'MN', 'TW', 'ZW'];

  constructor(
    private router: Router,
    private userPortalService: UserPortalService,
    private modalService: BsModalService
  ) {}

  evaluate(role: string | undefined) {
    this.permissions = {
      installator_view: role === 'INSTALLER' || role === 'SYSTEM_INTEGRATOR',
      building_manager_view: role === 'BUILDING_MANAGER',
      no_type: role === undefined
    };
  }

  async isLoggedIn(): Promise<boolean> {
    const newToken = new URLSearchParams(window.location.search).get('token');
    if (newToken) {
      sessionStorage.setItem('token', newToken);
      this._token = newToken;
      this.router.createUrlTree(['']);
    }

    if (!this._token) {
      this._token = sessionStorage.getItem('token');
      if (!this._token) {
        return false;
      }
    }

    await this.updateUserProfile();
    if (this.userProfile.data) {
      this.evaluate(
        localStorage.getItem('role') || this.userProfile.data.userType
      );
    }

    return true;
  }

  async updateUserProfile() {
    this.userProfile =
      (await this.userPortalService
        .getMyProfile(this.getToken())
        .toPromise()) || {};
  }

  logout() {
    this.clearToken();
    window.location.href =
      environment.loginUrl + window.location.origin + window.location.pathname;
  }

  clearToken() {
    this._token = null;
    sessionStorage.removeItem('token');
    localStorage.removeItem('role');
  }

  getToken(): string {
    return `ccstoken ${this._token}`;
  }

  getCountryCode(): string {
    if (this.userProfile.data) {
      return this.userProfile.data.isoCountryCode || 'IT';
    }
    return 'IT';
  }

  getCountryCodeNoDefault(): string {
    if (this.userProfile.data) {
      return this.userProfile.data.isoCountryCode || '';
    }

    return '';
  }

  getDateFormat(): string {
    return this.MDYFormatList.indexOf(this.getCountryCode()) !== -1
      ? 'MM/dd/y h:mm a'
      : this.YMDFormatList.indexOf(this.getCountryCode()) !== -1
      ? 'y/MM/dd h:mm a'
      : 'dd/MM/y HH:mm';
  }

  getName(): string {
    if (this.userProfile.data) {
      return (
        this.userProfile.data.firstName ||
        this.userProfile.data.loginEmail ||
        '--'
      );
    }
    return '--';
  }
  getEmail(): string {
    return this.userProfile.data?.loginEmail || '';
  }

  getImage(): string {
    return this.userProfile.data?.profilePictureUrl || '';
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  LoginService,
  LogoutService,
  SafeLoginResponseDto
} from 'src/app/api/dpcpf';
import {
  DeviceStatusDto,
  StatusAndConfigurationService
} from 'src/app/api/mugconf';
import { DeviceListElementDto, TreeService } from 'src/app/api/tree';
import { AuthService } from 'src/app/core/auth.service';
import { DeviceCompleteDto } from 'src/app/core/models';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetailService {
  device$ = new BehaviorSubject<DeviceCompleteDto>({} as any);
  updatedEmailStatus = new EventEmitter<boolean>();
  safeDeviceShared = new EventEmitter<boolean>(false);

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private loggoutService: LogoutService,
    private ts: TranslateService,
    private toastr: ToastrService,
    private treeService: TreeService,
    private statusconfService: StatusAndConfigurationService
  ) {}

  getDevice(
    deviceId: string,
    siteId: string,
    deviceUuid?: string
  ): Observable<DeviceCompleteDto> | Observable<never> {
    return this.treeService
      .getUserResourceList(
        this.authService.getToken(),
        siteId === '-' ? undefined : siteId,
        deviceId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ['pro_buildingmanager', 'pro_installer']
      )
      .pipe(
        mergeMap((r) => {
          if (!deviceId && deviceUuid) {
            r = [r[r.findIndex((x) => x.uuid === deviceUuid)]];
          }
          return this.getDeviceStatus(r);
        })
      );
  }

  getDeviceStatus(
    res: DeviceListElementDto[]
  ): Observable<DeviceCompleteDto> | Observable<never> {
    return this.statusconfService
      .getDeviceStatus(
        res[0].uuid!,
        this.authService.getToken(),
        undefined,
        true
      )
      .pipe(
        map((r: DeviceStatusDto) => {
          const device = {
            status: r,
            resource: res[0],
            id: res[0].authenticationId!
          };
          this.device$.next(device);

          return device;
        })
      );
  }

  logout(deviceId: string) {
    if (this.ssidIsValid(deviceId)) {
      this.loggoutService
        .logout(deviceId, JSON.parse(localStorage.getItem('ssid')!))
        .subscribe();

      localStorage.removeItem('device_id');
      localStorage.removeItem('ssid');
      localStorage.removeItem('ssid_validity');
    }
  }

  login(deviceId: string): Observable<number> | Observable<never> {
    if (this.ssidIsValid(deviceId)) {
      setTimeout(() => {
        this.safeDeviceShared.next(false);
      }, 400);
      return of((localStorage.getItem('ssid') as any) || 0);
    } else {
      return this.loginService.login(deviceId).pipe(
        map((r: SafeLoginResponseDto) => {
          localStorage.setItem('device_id', deviceId);
          localStorage.setItem('ssid', JSON.stringify(r.ssid));
          localStorage.setItem(
            'ssid_validity',
            JSON.stringify(Date.now() + 126000)
          );
          this.safeDeviceShared.next(false);
          return r.ssid || 0;
        }),

        catchError((err) => {
          this.toastr.clear();

          switch (err.error.device_error_code) {
            case 10:
              this.toastr.error(
                this.ts.instant('DEVICE.SAFE.ERROR_MAXIMUM_SESSIONS_REACHED'),
                this.ts.instant('GLOBAL.ERROR')
              );
              break;
            case -1:
              this.toastr.error(
                this.ts.instant('DEVICE.SAFE.ERROR_DEVICE_OFFLINE'),
                this.ts.instant('GLOBAL.ERROR')
              );
              break;
          }

          localStorage.removeItem('device_id');
          localStorage.removeItem('ssid');
          localStorage.removeItem('ssid_validity');
          this.safeDeviceShared.next(true);
          return EMPTY;
        })
      );
    }
  }

  ssidIsValid(deviceId: string) {
    if (deviceId === localStorage.getItem('device_id')) {
      return localStorage.getItem('ssid_validity') &&
        localStorage.getItem('ssid')
        ? JSON.parse(localStorage.getItem('ssid_validity') || '') > Date.now()
        : false;
    } else {
      return false;
    }
  }
}

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of power suppliers.
 */
export interface PowerSuppliersBitMaskDto {
  fault?: boolean;
  acLoss?: boolean;
  earthFault?: boolean;
  batteryLow?: boolean;
  batteryLoss?: boolean;
  batteryHighResistance?: boolean;
  chargerFault?: boolean;
  externalPowerFault?: boolean;
}

import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, finalize } from 'rxjs/operators';
import { SiteService } from 'src/app/api/myportal';
import { SiteDto } from 'src/app/api/myportal/model/siteDto';
import { AuthService } from 'src/app/core/auth.service';
import { mapStyles } from 'src/app/core/map-options';
import { Option } from 'src/app/core/models';
import {
  checkFormValidity,
  ifUserPresentIsRequired,
  isMobile,
  requiredAndTrimmed
} from 'src/app/core/utils';

@Component({
  selector: 'app-create-site-modal',
  templateUrl: './create-site-modal.component.html',
  styleUrls: ['./create-site-modal.component.scss']
})
export class CreateSiteModalComponent implements OnInit {
  readOnly: boolean;
  isEditing = false;
  site: SiteDto;
  imageDeleted: boolean;

  siteFormGroup: UntypedFormGroup;
  userFormGroup: UntypedFormGroup;
  addressFormGroup: UntypedFormGroup;
  imageUrl: SafeStyle;

  isLoading = false;
  types: Option[] = [];
  siteCreated = new EventEmitter<SiteDto>();

  options: google.maps.MapOptions = {
    disableDefaultUI: false,
    controlSize: 20,
    zoomControl: true,
    scaleControl: true,
    rotateControl: true,
    panControl: true,
    styles: mapStyles,
    streetViewControl: false
  };

  mapLatLng: {
    latitude: number;
    longitude: number;
  } = {
    latitude: 0,
    longitude: 0
  };

  siteNameControl = new UntypedFormControl('', requiredAndTrimmed());
  siteTypeControl = new UntypedFormControl('', Validators.required);
  siteImageControl = new UntypedFormControl('');
  siteNotesControl = new UntypedFormControl('');

  siteAddressControl = new UntypedFormControl('', requiredAndTrimmed());
  siteCityControl = new UntypedFormControl('', requiredAndTrimmed());
  siteZipControl = new UntypedFormControl('', requiredAndTrimmed());
  siteCountryControl = new UntypedFormControl('', requiredAndTrimmed());

  userNameControl = new UntypedFormControl('');
  userSurnameControl = new UntypedFormControl('');
  userCellControl = new UntypedFormControl('');
  userPhoneControl = new UntypedFormControl('');
  userEmailControl = new UntypedFormControl('');
  tagsArrayControl = new UntypedFormArray([]);
  tagFormControl = new UntypedFormControl('');

  mapStyles = mapStyles;
  selectedLocation: {
    icon: string;
    latitude: number;
    longitude: number;
  } | null = null;

  constructor(
    private modalRef: BsModalRef,
    private sitesService: SiteService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private ts: TranslateService
  ) {}

  get isMobile() {
    return isMobile;
  }

  imageChanged(v: string | undefined) {
    this.siteImageControl.setValue(v);
    this.imageDeleted = v ? false : true;
  }

  ngOnInit(): void {
    this.types = [
      {
        value: 'RESIDENTIAL',
        name: this.ts.instant('SITE.RESIDENTIAL')
      },
      { value: 'COMMERCIAL', name: this.ts.instant('SITE.COMMERCIAL') },
      { value: 'TERTIARY', name: this.ts.instant('SITE.TERTIARY') }
    ];

    if (!this.isMobile) {
      window.navigator.geolocation.getCurrentPosition((pos) => {
        this.mapLatLng = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        };
      });
    }

    this.addressFormGroup = new UntypedFormGroup({
      addressLine1: this.siteAddressControl,
      city: this.siteCityControl,
      country: this.siteCountryControl,
      zipCode: this.siteZipControl,
      latitude: new UntypedFormControl(0),
      longitude: new UntypedFormControl(0)
    });

    this.userFormGroup = new UntypedFormGroup(
      {
        name: this.userNameControl,
        surname: this.userSurnameControl,
        phone: this.userPhoneControl,
        mobile: this.userCellControl,
        email: this.userEmailControl,
        role: new UntypedFormControl('')
      },
      ifUserPresentIsRequired()
    );

    this.siteFormGroup = new UntypedFormGroup({
      type: this.siteTypeControl,
      name: this.siteNameControl,
      description: new UntypedFormControl(null),
      address: this.addressFormGroup,
      notes: this.siteNotesControl,
      imageBase64: this.siteImageControl,
      userMetadata: new UntypedFormGroup({
        contacts: new UntypedFormArray([this.userFormGroup]),
        tags: this.tagsArrayControl,
        preferred: new UntypedFormControl(false)
      }),
      siteContext: new UntypedFormControl('pro_buildingmanager')
    });

    this.addressFormGroup.valueChanges
      .pipe(debounceTime(500))
      .subscribe((v) => {
        this.calculateMapMarker(v);
      });

    if (this.readOnly) {
      this.siteFormGroup.disable();
      this.userFormGroup.disable();
    }

    if (this.site) {
      this.siteFormGroup.patchValue(this.site);
      this.imageUrl = this.site.imageUrl
        ? `url('${this.site.imageUrl}?v=${Date.now()}')`
        : '';

      if (this.site.userMetadata && this.site.userMetadata.tags) {
        this.site.userMetadata.tags.forEach((element) => {
          this.tagsArrayControl.push(new UntypedFormControl(element));
        });
      }

      if (this.site.address.latitude && this.site.address.longitude) {
        this.setLatLng(this.site.address.latitude, this.site.address.longitude);
      }
    }
  }

  calculateMapMarker(v: any) {
    console.log('Asdsdsdaas');

    const geocoder = new google.maps.Geocoder();
    const request = {
      address: `${v.addressLine1} ${v.city} ${v.country} ${v.zipCode}`
    };

    geocoder.geocode(request, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const latitude = results![0].geometry.location.lat();
        const longitude = results![0].geometry.location.lng();

        this.setLatLng(latitude, longitude);
      }
    });
  }

  setLatLng(latitude: number, longitude: number) {
    this.addressFormGroup.controls['latitude'].setValue(latitude, {
      onlySelf: true
    });
    this.addressFormGroup.controls['longitude'].setValue(longitude, {
      onlySelf: true
    });

    this.mapLatLng = {
      latitude,
      longitude
    };
    this.selectedLocation = {
      icon: 'assets/images/icons/marker.png',
      latitude,
      longitude
    };
  }

  calculateAddress(e: any) {
    if (!this.readOnly) {
      this.setLatLng(e.latLng.lat(), e.latLng.lng());

      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      const request = {
        location: latlng
      };

      geocoder.geocode(request, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results![0].address_components != null) {
            const address = results![0].address_components;
            this.siteZipControl.setValue(
              this.getAddressValue(address, 'postal_code'),
              { onlySelf: true }
            );
            this.siteCountryControl.setValue(
              this.getAddressValue(address, 'country'),
              { onlySelf: true }
            );
            this.siteCityControl.setValue(
              this.getAddressValue(address, 'administrative_area_level_3') ||
                this.getAddressValue(address, 'administrative_area_level_2'),
              { onlySelf: true }
            );
            this.siteAddressControl.setValue(
              `${this.getAddressValue(address, 'route')} ${this.getAddressValue(
                address,
                'street_number'
              )}
                `,
              { onlySelf: true }
            );

            this.addressFormGroup.updateValueAndValidity();
          }
        }
      });
    }
  }

  getAddressValue(address: any, type: string) {
    return address.find((a: any) => a.types.indexOf(type) > -1)
      ? address.find((a: any) => a.types.indexOf(type) > -1).long_name || ''
      : '';
  }

  enableEdit() {
    this.readOnly = false;
    this.isEditing = true;
    this.siteFormGroup.enable();
    this.userFormGroup.enable();
  }

  close() {
    this.modalRef.hide();
  }

  addTag() {
    if (this.tagFormControl.value) {
      this.tagsArrayControl.value.push(this.tagFormControl.value);
      this.tagFormControl.setValue('');
    }
  }

  removeTag(index: number) {
    this.tagsArrayControl.value.splice(index, 1);
  }

  save() {
    if (checkFormValidity(this.siteFormGroup) && !this.isLoading) {
      this.isLoading = true;
      if (
        !this.userNameControl.value &&
        !this.userSurnameControl.value &&
        !this.userEmailControl.value
      ) {
        delete this.siteFormGroup.value.userMetadata.contacts;
      }

      if (this.isEditing) {
        this.sitesService
          .updateSite(
            this.site.authenticationId!,
            this.siteFormGroup.value as SiteDto,
            this.authService.getToken()
          )
          .pipe(
            finalize(() => {
              this.close();
              this.isLoading = false;
            })
          )
          .subscribe(() => {
            if (this.imageDeleted && !this.siteImageControl.value) {
              this.sitesService
                .deleteSiteImage(
                  this.authService.getToken(),
                  this.site.authenticationId!
                )
                .subscribe(() => {
                  this.siteCreated.next(this.site);
                  this.toastrService.success(
                    this.ts.instant('SITE.SITE_EDITED'),
                    this.ts.instant('GLOBAL.SUCCESS')
                  );
                });
            } else {
              this.siteCreated.next(this.site);
              this.toastrService.success(
                this.ts.instant('SITE.SITE_EDITED'),
                this.ts.instant('GLOBAL.SUCCESS')
              );
            }
          });
      } else {
        this.sitesService
          .addSite(
            this.siteFormGroup.value as SiteDto,
            this.authService.getToken()
          )
          .pipe(
            finalize(() => {
              this.close();
              this.isLoading = false;
            })
          )
          .subscribe((site: SiteDto) => {
            this.siteCreated.next(site);
            this.toastrService.success(
              this.ts.instant('SITE.SITE_CREATED'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
          });
      }
    }
  }
}

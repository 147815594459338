import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SiteService } from '../api/myportal';
import { SiteDto } from '../api/myportal/model/siteDto';
import { AuthService } from '../core/auth.service';
import { UserService } from '../shared/utils/user.service';

@Injectable({
  providedIn: 'root'
})
export class SiteDetailResolverService implements Resolve<SiteDto> {
  constructor(
    private sitesService: SiteService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<SiteDto> | Observable<never> {
    const siteId: string = route.params.siteId;
    if (siteId !== '-') {
      this.userService.getAllSiteUsersAndRoles(siteId);
      return this.getSite(siteId).pipe(
        take(1),
        catchError(() => {
          return EMPTY;
        })
      );
    } else {
      return EMPTY;
    }
  }

  getSite(siteId: string) {
    return this.sitesService.getSite(siteId, this.authService.getToken());
  }
}

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ClearZoneHistoryDto {
  /**
   * Zone index is an integer number indicating the index of the zone in device’s zones list.
   */
  zoneIndex: number;
}

import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {
  GroupService,
  GroupUserRequestDto,
  SiteDto
} from 'src/app/api/myportal';
import { AuthService } from 'src/app/core/auth.service';
import {
  checkFormValidity,
  mustBeDiffFromOwner,
  mustNotBeAlreadyAnUser
} from 'src/app/core/utils';
import { Roles, RolesWLabel, UserRole, rolesLabels } from '../utils/users';

@Component({
  selector: 'app-share-user-modal',
  templateUrl: './share-user-modal.component.html',
  styleUrls: ['./share-user-modal.component.scss']
})
export class ShareUserModalComponent implements OnInit {
  isLoading = false;
  shareGroup: UntypedFormGroup;
  currentUserEmail: string;
  @Input() site: SiteDto;
  set userRole(ur: UserRole | undefined) {
    if (ur === 'INSTALLER' || ur === 'INSTALLER-COLLABORATOR') {
      this.roles = this.roles.filter(
        (x) =>
          x.name !== 'BUILDINGMANAGER-COLLABORATOR' &&
          x.name !== 'BUILDINGMANAGER' &&
          x.name !== 'MAINTAINER'
      );
    }
  }
  emailControl = new UntypedFormControl('');
  roleControl = new UntypedFormControl('', Validators.required);
  shared = new EventEmitter<void>();

  siteUsers: string[] = [];
  roles: RolesWLabel[];
  set roleList(r: Roles[]) {
    this.roles = [];
    r.forEach((x) => {
      if (x.name !== 'ADMIN') {
        this.roles.push({
          ...x,
          label: this.ts.instant(rolesLabels[x.name])
        });
      }
    });
    this.roles.sort((a, b) => {
      if (a.name.split('-')[0] === b.name.split('-')[0]) {
        return a.name.split('-')[1] ? 1 : -1;
      } else {
        return a.name > b.name ? -1 : 1;
      }
    });
  }

  constructor(
    private modalRef: BsModalRef,
    private ts: TranslateService,
    private toastr: ToastrService,
    private groupService: GroupService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.shareGroup = new UntypedFormGroup({
      user: this.emailControl,
      role: this.roleControl
    });

    this.emailControl.setValidators([
      mustNotBeAlreadyAnUser(this.siteUsers),
      mustBeDiffFromOwner(this.currentUserEmail),
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ]);
  }

  close() {
    this.modalRef.hide();
  }

  send() {
    if (checkFormValidity(this.shareGroup) && !this.isLoading) {
      this.isLoading = true;
      const share: GroupUserRequestDto = {
        loginEmail: this.emailControl.value
      };
      this.groupService
        .addUserToGroup(
          this.roleControl.value,
          false,
          share,
          this.authService.getToken()
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.shared.next();
            this.modalRef.hide();
          },
          (error) => {
            if (error.error.error_code === 'FAILED_CONSTRAINT') {
              this.toastr.clear();
              this.toastr.error(
                this.ts.instant('DEVICE.HTTP_ERROR_SHARE'),
                this.ts.instant('GLOBAL.ERROR')
              );
            }
          }
        );
    }
  }
}

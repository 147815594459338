/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.4+develop.011
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Data about container entries
 */
export interface ContainerEntryDto {
  /**
   * Reference to child container
   */
  link: string;
  /**
   * Type of the entry (container/device)
   */
  type: ContainerEntryDto.TypeEnum;
}
export namespace ContainerEntryDto {
  export type TypeEnum = 'DEVICE' | 'CONTAINER';
  export const TypeEnum = {
    DEVICE: 'DEVICE' as TypeEnum,
    CONTAINER: 'CONTAINER' as TypeEnum
  };
}

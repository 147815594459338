/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PanelOwnerAssignDto {
  /**
   * Name is a string containing the name of the owner
   */
  name: string;
  /**
   * Address is a string containing the street address of the owner
   */
  address: string;
  /**
   * Contact Person is a string containing the contact person for the panel
   */
  contactPersonName: string;
  /**
   * Contact Person\'s phone is a string containing the contact person\'s phone
   */
  contactPersonPhoneNumber: string;
}

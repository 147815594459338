/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status2 of device.
 */
export interface DeviceStatus2BitMaskDto {
  isolated?: boolean;
  chamberFault?: boolean;
  maintenance?: boolean;
  outputOpen?: boolean;
  outputShort?: boolean;
  inputOpen?: boolean;
  inputShort?: boolean;
  outputCh3Open?: boolean;
  outputCh3Short?: boolean;
  outputCh4Open?: boolean;
  outputCh4Short?: boolean;
  inputCh1Open?: boolean;
  inputCh1Short?: boolean;
  inputCh2Open?: boolean;
  inputCh2Short?: boolean;
  inputCh3Open?: boolean;
  inputCh3Short?: boolean;
  inputCh4Open?: boolean;
  inputCh4Short?: boolean;
  output1Monitored?: boolean;
  output1TypeFault?: boolean;
  output1PowerFault?: boolean;
  output2Monitored?: boolean;
  output2TypeFault?: boolean;
  output2PowerFault?: boolean;
  powerFault?: boolean;
  communicationError?: boolean;
  openZone?: boolean;
  shortZone?: boolean;
  detectorRemoved?: boolean;
  /**
   * true if zone where device belongs is in tests
   */
  test?: boolean;
}

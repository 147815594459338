/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status2 of IO modules.
 */
export interface IOModulesStatus2BitMaskDto {
  outputChannel1On?: boolean;
  outputChannel2On?: boolean;
  outputChannel3On?: boolean;
  outputChannel4On?: boolean;
  inputChannel1Activated?: boolean;
  inputChannel2Activated?: boolean;
  inputChannel3Activated?: boolean;
  inputChannel4Activated?: boolean;
  outputChannel3Open?: boolean;
  outputChannel3Short?: boolean;
  outputChannel4Open?: boolean;
  outputChannel4Short?: boolean;
  inputChannel1Open?: boolean;
  inputChannel1Short?: boolean;
  inputChannel2Open?: boolean;
  inputChannel2Short?: boolean;
  inputChannel3Open?: boolean;
  inputChannel3Short?: boolean;
  inputChannel4Open?: boolean;
  inputChannel4Short?: boolean;
  out1Monitored?: boolean;
  out1TypeFault?: boolean;
  powerFaultOut1?: boolean;
  out2Monitored?: boolean;
  out2TypeFault?: boolean;
  powerFaultOut2?: boolean;
}

/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.4+develop.011
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { AddElementDto } from '../model/models';
import { ContainerDto } from '../model/models';
import { DeviceInfoDto } from '../model/models';
import { DeviceInfoListReqDto } from '../model/models';
import { DeviceInfoListResDto } from '../model/models';
import { DeviceListDto } from '../model/models';
import { DeviceListElementCountDto } from '../model/models';
import { DeviceListElementDto } from '../model/models';
import { ElementDto } from '../model/models';
import { ErrorDto } from '../model/models';
import { PermissionListDto } from '../model/models';
import { RenameObjectDto } from '../model/models';
import { ShareRoleDto } from '../model/models';
import { TransferOwnershipObjectDto } from '../model/models';

import { TREE_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  protected basePath = 'https://api.comelitgroup.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(TREE_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Add a device to the user resource list
   * @param authorization Authorization token
   * @param apiKey ApiKey
   * @param generateUnlinkToken If true a new unlink token is generated and returned to user
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addDeviceToResourceList(
    authorization?: string,
    apiKey?: string,
    generateUnlinkToken?: boolean,
    body?: DeviceListElementDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceListElementDto>;
  public addDeviceToResourceList(
    authorization?: string,
    apiKey?: string,
    generateUnlinkToken?: boolean,
    body?: DeviceListElementDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceListElementDto>>;
  public addDeviceToResourceList(
    authorization?: string,
    apiKey?: string,
    generateUnlinkToken?: boolean,
    body?: DeviceListElementDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceListElementDto>>;
  public addDeviceToResourceList(
    authorization?: string,
    apiKey?: string,
    generateUnlinkToken?: boolean,
    body?: DeviceListElementDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (generateUnlinkToken !== undefined && generateUnlinkToken !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>generateUnlinkToken,
        'generateUnlinkToken'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<DeviceListElementDto>(
      `${this.configuration.basePath}/servicerest/eps/tree/resourcelist/adddevice`,
      body,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add a device to the container
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addElements(
    containerUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ElementDto>;
  public addElements(
    containerUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ElementDto>>;
  public addElements(
    containerUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ElementDto>>;
  public addElements(
    containerUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling addElements.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling addElements.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<ElementDto>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/addelement`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add a device to the default user container
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addElementsToDefaultContainer(
    authorization: string,
    body?: AddElementDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ElementDto>;
  public addElementsToDefaultContainer(
    authorization: string,
    body?: AddElementDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ElementDto>>;
  public addElementsToDefaultContainer(
    authorization: string,
    body?: AddElementDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ElementDto>>;
  public addElementsToDefaultContainer(
    authorization: string,
    body?: AddElementDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling addElementsToDefaultContainer.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<ElementDto>(
      `${this.configuration.basePath}/servicerest/eps/tree/containers/default/addelement`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new parent (site) container
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create(
    authorization: string,
    body?: ContainerDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ContainerDto>;
  public create(
    authorization: string,
    body?: ContainerDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ContainerDto>>;
  public create(
    authorization: string,
    body?: ContainerDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ContainerDto>>;
  public create(
    authorization: string,
    body?: ContainerDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling create.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<ContainerDto>(
      `${this.configuration.basePath}/servicerest/eps/tree/containers/create`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete a container
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteContainer(
    containerUuid: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public deleteContainer(
    containerUuid: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public deleteContainer(
    containerUuid: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public deleteContainer(
    containerUuid: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling deleteContainer.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling deleteContainer.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/remove`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the resource tree (starting from the given node)
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param maxDepth Maximum tree depth to stop searching for child nodes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContainerChildrenElementsList(
    containerUuid: string,
    authorization: string,
    maxDepth: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceListDto>;
  public getContainerChildrenElementsList(
    containerUuid: string,
    authorization: string,
    maxDepth: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceListDto>>;
  public getContainerChildrenElementsList(
    containerUuid: string,
    authorization: string,
    maxDepth: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceListDto>>;
  public getContainerChildrenElementsList(
    containerUuid: string,
    authorization: string,
    maxDepth: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling getContainerChildrenElementsList.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getContainerChildrenElementsList.'
      );
    }
    if (maxDepth === null || maxDepth === undefined) {
      throw new Error(
        'Required parameter maxDepth was null or undefined when calling getContainerChildrenElementsList.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (maxDepth !== undefined && maxDepth !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>maxDepth,
        'max_depth'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<DeviceListDto>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/elements/list`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the permission for the given container
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContainerPermissionList(
    containerUuid: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<PermissionListDto>;
  public getContainerPermissionList(
    containerUuid: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<PermissionListDto>>;
  public getContainerPermissionList(
    containerUuid: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<PermissionListDto>>;
  public getContainerPermissionList(
    containerUuid: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling getContainerPermissionList.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getContainerPermissionList.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<PermissionListDto>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/permissions/list`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device info
   * @param idType ID type
   * @param idValue ID value
   * @param authorization Authorization token
   * @param apiKey ApiKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceInfo(
    idType: 'UUID' | 'AUTH_ID' | 'ID32',
    idValue: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceInfoDto>;
  public getDeviceInfo(
    idType: 'UUID' | 'AUTH_ID' | 'ID32',
    idValue: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceInfoDto>>;
  public getDeviceInfo(
    idType: 'UUID' | 'AUTH_ID' | 'ID32',
    idValue: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceInfoDto>>;
  public getDeviceInfo(
    idType: 'UUID' | 'AUTH_ID' | 'ID32',
    idValue: string,
    authorization?: string,
    apiKey?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (idType === null || idType === undefined) {
      throw new Error(
        'Required parameter idType was null or undefined when calling getDeviceInfo.'
      );
    }
    if (idValue === null || idValue === undefined) {
      throw new Error(
        'Required parameter idValue was null or undefined when calling getDeviceInfo.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<DeviceInfoDto>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/devices/${encodeURIComponent(
        String(idType)
      )}/${encodeURIComponent(String(idValue))}/info`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the permission for the given device
   * @param deviceUuid Device UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevicePermissionList(
    deviceUuid: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<PermissionListDto>;
  public getDevicePermissionList(
    deviceUuid: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<PermissionListDto>>;
  public getDevicePermissionList(
    deviceUuid: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<PermissionListDto>>;
  public getDevicePermissionList(
    deviceUuid: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling getDevicePermissionList.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getDevicePermissionList.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<PermissionListDto>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/devices/${encodeURIComponent(
        String(deviceUuid)
      )}/permissions/list`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device info
   * @param authorization Authorization token
   * @param apiKey ApiKey
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevicesInfo(
    authorization?: string,
    apiKey?: string,
    body?: DeviceInfoListReqDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceInfoListResDto>;
  public getDevicesInfo(
    authorization?: string,
    apiKey?: string,
    body?: DeviceInfoListReqDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceInfoListResDto>>;
  public getDevicesInfo(
    authorization?: string,
    apiKey?: string,
    body?: DeviceInfoListReqDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceInfoListResDto>>;
  public getDevicesInfo(
    authorization?: string,
    apiKey?: string,
    body?: DeviceInfoListReqDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<DeviceInfoListResDto>(
      `${this.configuration.basePath}/servicerest/eps/tree/devices/list/info`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the resource tree (starting from root node)
   * @param authorization Authorization token
   * @param maxDepth Maximum tree depth to stop searching for child nodes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getElementsList(
    authorization: string,
    maxDepth: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceListDto>;
  public getElementsList(
    authorization: string,
    maxDepth: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceListDto>>;
  public getElementsList(
    authorization: string,
    maxDepth: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceListDto>>;
  public getElementsList(
    authorization: string,
    maxDepth: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getElementsList.'
      );
    }
    if (maxDepth === null || maxDepth === undefined) {
      throw new Error(
        'Required parameter maxDepth was null or undefined when calling getElementsList.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (maxDepth !== undefined && maxDepth !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>maxDepth,
        'max_depth'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<DeviceListDto>(
      `${this.configuration.basePath}/servicerest/eps/tree/elements/list`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the resource list of the user logged in currently
   * @param authorization Authorization token
   * @param siteAuthId Site authenticationId
   * @param deviceAuthId Device authenticationId
   * @param limit Limit
   * @param offset Offset
   * @param sortField Order by field
   * @param sortOrder Sort Order
   * @param filter Filter by DEVICE NAME or DEVICE MODEL or OWNER EMAIL or SITE NAME
   * @param withStatus Include device status data (limit must be set to enable this feature)
   * @param onlyWithIssues Include only device with status issues (requires withStatus to be set to true)
   * @param resourceContext Include only device with given resource contexts
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserResourceList(
    authorization: string,
    siteAuthId?: string,
    deviceAuthId?: string,
    limit?: number,
    offset?: number,
    sortField?:
      | 'DESCRIPTION'
      | 'MODEL'
      | 'STATUS'
      | 'SERVICE_STATUS'
      | 'FW_VER'
      | 'SITE'
      | 'OWNER',
    sortOrder?: 'ASC' | 'DESC',
    filter?: string,
    withStatus?: boolean,
    onlyWithIssues?: boolean,
    resourceContext?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<DeviceListElementDto>>;
  public getUserResourceList(
    authorization: string,
    siteAuthId?: string,
    deviceAuthId?: string,
    limit?: number,
    offset?: number,
    sortField?:
      | 'DESCRIPTION'
      | 'MODEL'
      | 'STATUS'
      | 'SERVICE_STATUS'
      | 'FW_VER'
      | 'SITE'
      | 'OWNER',
    sortOrder?: 'ASC' | 'DESC',
    filter?: string,
    withStatus?: boolean,
    onlyWithIssues?: boolean,
    resourceContext?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<DeviceListElementDto>>>;
  public getUserResourceList(
    authorization: string,
    siteAuthId?: string,
    deviceAuthId?: string,
    limit?: number,
    offset?: number,
    sortField?:
      | 'DESCRIPTION'
      | 'MODEL'
      | 'STATUS'
      | 'SERVICE_STATUS'
      | 'FW_VER'
      | 'SITE'
      | 'OWNER',
    sortOrder?: 'ASC' | 'DESC',
    filter?: string,
    withStatus?: boolean,
    onlyWithIssues?: boolean,
    resourceContext?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<DeviceListElementDto>>>;
  public getUserResourceList(
    authorization: string,
    siteAuthId?: string,
    deviceAuthId?: string,
    limit?: number,
    offset?: number,
    sortField?:
      | 'DESCRIPTION'
      | 'MODEL'
      | 'STATUS'
      | 'SERVICE_STATUS'
      | 'FW_VER'
      | 'SITE'
      | 'OWNER',
    sortOrder?: 'ASC' | 'DESC',
    filter?: string,
    withStatus?: boolean,
    onlyWithIssues?: boolean,
    resourceContext?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getUserResourceList.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (siteAuthId !== undefined && siteAuthId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>siteAuthId,
        'siteAuthId'
      );
    }
    if (deviceAuthId !== undefined && deviceAuthId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>deviceAuthId,
        'deviceAuthId'
      );
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (sortField !== undefined && sortField !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>sortField,
        'sortField'
      );
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>sortOrder,
        'sortOrder'
      );
    }
    if (filter !== undefined && filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>filter,
        'filter'
      );
    }
    if (withStatus !== undefined && withStatus !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>withStatus,
        'withStatus'
      );
    }
    if (onlyWithIssues !== undefined && onlyWithIssues !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>onlyWithIssues,
        'onlyWithIssues'
      );
    }
    if (resourceContext) {
      resourceContext.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'resourceContext'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<DeviceListElementDto>>(
      `${this.configuration.basePath}/servicerest/eps/tree/resourcelist`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve the resource list elements count of the user logged in currently
   * @param authorization Authorization token
   * @param siteAuthId Site authenticationId
   * @param filter Filter by DEVICE NAME or DEVICE MODEL or OWNER EMAIL or SITE NAME
   * @param onlyWithIssues Include only device with status issues (requires withStatus to be set to true)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserResourceListCount(
    authorization: string,
    siteAuthId?: string,
    filter?: string,
    onlyWithIssues?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceListElementCountDto>;
  public getUserResourceListCount(
    authorization: string,
    siteAuthId?: string,
    filter?: string,
    onlyWithIssues?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceListElementCountDto>>;
  public getUserResourceListCount(
    authorization: string,
    siteAuthId?: string,
    filter?: string,
    onlyWithIssues?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceListElementCountDto>>;
  public getUserResourceListCount(
    authorization: string,
    siteAuthId?: string,
    filter?: string,
    onlyWithIssues?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling getUserResourceListCount.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (siteAuthId !== undefined && siteAuthId !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>siteAuthId,
        'siteAuthId'
      );
    }
    if (filter !== undefined && filter !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>filter,
        'filter'
      );
    }
    if (onlyWithIssues !== undefined && onlyWithIssues !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>onlyWithIssues,
        'onlyWithIssues'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<DeviceListElementCountDto>(
      `${this.configuration.basePath}/servicerest/eps/tree/resourcelist/count`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Remove device from user resource list
   * @param deviceAuthenticaionId Device AuthenticationId
   * @param authorization Authorization token
   * @param apiKey ApiKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeResourceListEntry(
    deviceAuthenticaionId: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public removeResourceListEntry(
    deviceAuthenticaionId: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public removeResourceListEntry(
    deviceAuthenticaionId: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public removeResourceListEntry(
    deviceAuthenticaionId: string,
    authorization?: string,
    apiKey?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceAuthenticaionId === null || deviceAuthenticaionId === undefined) {
      throw new Error(
        'Required parameter deviceAuthenticaionId was null or undefined when calling removeResourceListEntry.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/resourcelist/${encodeURIComponent(
        String(deviceAuthenticaionId)
      )}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Rename a container
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public renameContainer(
    containerUuid: string,
    authorization: string,
    body?: RenameObjectDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public renameContainer(
    containerUuid: string,
    authorization: string,
    body?: RenameObjectDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public renameContainer(
    containerUuid: string,
    authorization: string,
    body?: RenameObjectDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public renameContainer(
    containerUuid: string,
    authorization: string,
    body?: RenameObjectDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling renameContainer.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling renameContainer.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/rename`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Rename a device
   * @param deviceUuid Device UUID
   * @param authorization Authorization token
   * @param apiKey ApiKey
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public renameDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    body?: RenameObjectDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public renameDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    body?: RenameObjectDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public renameDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    body?: RenameObjectDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public renameDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    body?: RenameObjectDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling renameDevice.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/devices/${encodeURIComponent(
        String(deviceUuid)
      )}/rename`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Share a container with another user
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public shareContainerRoles(
    containerUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public shareContainerRoles(
    containerUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public shareContainerRoles(
    containerUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public shareContainerRoles(
    containerUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling shareContainerRoles.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling shareContainerRoles.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/share`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Share a device with another user
   * @param deviceUuid Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public shareDeviceRoles(
    deviceUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public shareDeviceRoles(
    deviceUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public shareDeviceRoles(
    deviceUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public shareDeviceRoles(
    deviceUuid: string,
    authorization: string,
    body?: ShareRoleDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling shareDeviceRoles.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling shareDeviceRoles.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/devices/${encodeURIComponent(
        String(deviceUuid)
      )}/share`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Unlink the device from its current parent container and associate the new one at its place
   * @param oldDeviceUuid Old device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public substituteDevice(
    oldDeviceUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<ElementDto>;
  public substituteDevice(
    oldDeviceUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<ElementDto>>;
  public substituteDevice(
    oldDeviceUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<ElementDto>>;
  public substituteDevice(
    oldDeviceUuid: string,
    authorization: string,
    body?: AddElementDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (oldDeviceUuid === null || oldDeviceUuid === undefined) {
      throw new Error(
        'Required parameter oldDeviceUuid was null or undefined when calling substituteDevice.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling substituteDevice.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.put<ElementDto>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/devices/${encodeURIComponent(
        String(oldDeviceUuid)
      )}/substitute`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Transfer a container ownership to another user
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transferContainerOwnership(
    containerUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public transferContainerOwnership(
    containerUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public transferContainerOwnership(
    containerUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public transferContainerOwnership(
    containerUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling transferContainerOwnership.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling transferContainerOwnership.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/transfer_ownership`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Transfer a device ownership to another user
   * @param deviceUuid Device UUID
   * @param authorization Authorization token
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transferDeviceOwnership(
    deviceUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public transferDeviceOwnership(
    deviceUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public transferDeviceOwnership(
    deviceUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public transferDeviceOwnership(
    deviceUuid: string,
    authorization: string,
    body?: TransferOwnershipObjectDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling transferDeviceOwnership.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling transferDeviceOwnership.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/devices/${encodeURIComponent(
        String(deviceUuid)
      )}/transfer_ownership`,
      body,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Unlink the container from the current user (so it can no longer be accessed)
   * @param containerUuid Container UUID
   * @param authorization Authorization token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public unlinkContainer(
    containerUuid: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public unlinkContainer(
    containerUuid: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public unlinkContainer(
    containerUuid: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public unlinkContainer(
    containerUuid: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (containerUuid === null || containerUuid === undefined) {
      throw new Error(
        'Required parameter containerUuid was null or undefined when calling unlinkContainer.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling unlinkContainer.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/containers/${encodeURIComponent(
        String(containerUuid)
      )}/unlink`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Unlink the device from its current parent container (thus enabling it to be linked to another container)
   * @param deviceUuid Device UUID
   * @param authorization Authorization token
   * @param apiKey ApiKey
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public unlinkDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public unlinkDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public unlinkDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public unlinkDevice(
    deviceUuid: string,
    authorization?: string,
    apiKey?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling unlinkDevice.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (apiKey !== undefined && apiKey !== null) {
      headers = headers.set('ApiKey', String(apiKey));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<object>(
      `${
        this.configuration.basePath
      }/servicerest/eps/tree/devices/${encodeURIComponent(
        String(deviceUuid)
      )}/unlink`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Unlink the device from all links
   * @param authorization Authorization token
   * @param unlinkToken Unlink token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public unlinkDeviceByUnlinkToken(
    authorization: string,
    unlinkToken: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<object>;
  public unlinkDeviceByUnlinkToken(
    authorization: string,
    unlinkToken: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<object>>;
  public unlinkDeviceByUnlinkToken(
    authorization: string,
    unlinkToken: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<object>>;
  public unlinkDeviceByUnlinkToken(
    authorization: string,
    unlinkToken: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling unlinkDeviceByUnlinkToken.'
      );
    }
    if (unlinkToken === null || unlinkToken === undefined) {
      throw new Error(
        'Required parameter unlinkToken was null or undefined when calling unlinkDeviceByUnlinkToken.'
      );
    }

    let headers = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      headers = headers.set('Authorization', String(authorization));
    }
    if (unlinkToken !== undefined && unlinkToken !== null) {
      headers = headers.set('UnlinkToken', String(unlinkToken));
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.delete<object>(
      `${this.configuration.basePath}/servicerest/eps/tree/devices/unlink`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.4+develop.011
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContainerEntryDto } from './containerEntryDto';

/**
 * Container related data
 */
export interface ContainerDto {
  /**
   * Parent container UUID (if any)
   */
  parentContainerUuid?: string;
  /**
   * Custom label of the element (shown in tree view)
   */
  label: string;
  /**
   * Element UUID
   */
  uuid: string;
  /**
   * Site authenticationId
   */
  siteAuthenticationId?: string;
  /**
   * List of elements (child containers and/or devices) contained in the container
   */
  components?: Array<ContainerEntryDto>;
  /**
   * Container type
   */
  type: ContainerDto.TypeEnum;
}
export namespace ContainerDto {
  export type TypeEnum =
    | 'GENERIC'
    | 'SITE'
    | 'BUILDING'
    | 'STAIRCASE'
    | 'FLOOR'
    | 'HOUSE';
  export const TypeEnum = {
    GENERIC: 'GENERIC' as TypeEnum,
    SITE: 'SITE' as TypeEnum,
    BUILDING: 'BUILDING' as TypeEnum,
    STAIRCASE: 'STAIRCASE' as TypeEnum,
    FLOOR: 'FLOOR' as TypeEnum,
    HOUSE: 'HOUSE' as TypeEnum
  };
}

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control panel status.
 */
export interface ControlPanelStatusBitMaskDto {
  fire?: boolean;
  prealarm?: boolean;
  generalFault?: boolean;
  systemFault?: boolean;
  soundersSilenced?: boolean;
  sounderFault?: boolean;
  delayOverride?: boolean;
  commonDisable?: boolean;
  disabledZone?: boolean;
  disabledSounder?: boolean;
  disabledFireBrigadeOutput?: boolean;
  disabledFireProtectionOutput?: boolean;
  disabledBuzzer?: boolean;
  disabledEarthFault?: boolean;
  disabledExternalPowerSupplyFault?: boolean;
  test?: boolean;
  technicalAlarm?: boolean;
  fireConfirmed?: boolean;
  fireBrigadeOutputFault?: boolean;
  protectionConfirmed?: boolean;
  protectionPanelFault?: boolean;
  buzzerOn?: boolean;
  maintenance?: boolean;
  evac?: boolean;
}

/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.4+develop.011
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Data about the new element (container/device) to be added
 */
export interface AddElementDto {
  /**
   * Device authenticationId
   */
  elementUuid?: string;
  /**
   * Device ID32 found on label
   */
  id32: string;
  /**
   * Type of the new entry (container or device)
   */
  type: AddElementDto.TypeEnum;
  /**
   * Custom label to show in the tree view
   */
  label?: string;
  /**
   * Site authenticationId to which the device is being added to
   */
  siteAuthenticationId?: string;
}
export namespace AddElementDto {
  export type TypeEnum = 'DEVICE';
  export const TypeEnum = {
    DEVICE: 'DEVICE' as TypeEnum
  };
}

<div class="container-fluid">
  <h1>{{ pageName }}</h1>

  <div class="row mt-3">
    <div class="col-12 col-xl-8">
      <div class="card mt-3 pb-3">
        <div class="card-header">
          <h2>{{ 'USER_DEVICE.DETAILS' | translate }}</h2>
          <button
            id="edit"
            *ngIf="!isEditing && userDevice.backline === 0"
            class="button-secondary"
            (click)="editUserDevice()"
          >
            <i class="material-icons">edit</i>
            {{ 'USER_DEVICE.EDIT_DATA' | translate }}
          </button>
          <div class="button-box" *ngIf="isEditing">
            <button
              id="cancel"
              class="button-secondary mr-3"
              (click)="closeEdit(true)"
            >
              {{ 'GLOBAL.CANCEL' | translate }}
            </button>
            <app-loading-button
              id="save"
              buttonClass="button-primary"
              [isLoading]="isLoading"
              (click)="updateUserDevice()"
              buttonType="button"
              >{{ 'GLOBAL.SAVE' | translate }}</app-loading-button
            >
          </div>
        </div>
        <div class="card-body pt-2">
          <form [formGroup]="udFormGroup">
            <ul class="info-list">
              <!-- DEVICE type -->
              <li>
                <span>{{ 'USER_DEVICE.TYPE' | translate }}</span>
                <ng-select
                  id="type"
                  [items]="typesToUse"
                  bindLabel="label"
                  bindValue="index"
                  [formControl]="typeControl"
                  [searchable]="false"
                  [clearable]="false"
                ></ng-select>
              </li>

              <!-- DESCRIPTION -->
              <li *ngIf="typeControl.value !== 1">
                <span>{{ 'USER_DEVICE.DESCRIPTION' | translate }}</span>
                <span>
                  <b *ngIf="!descrControl.value && !isEditing"> -- </b>
                  <input
                    id="descr"
                    [formControl]="descrControl"
                    type="text"
                    class="custom-input"
                    [placeholder]="
                      !isEditing ? '' : ('USER_DEVICE.DESCRIPTION' | translate)
                    "
                  />
                </span>
              </li>

              <!-- EXT UNIT DIR -->
              <li *ngIf="!isSbc(device.resource.deviceModelId)">
                <span>{{ 'USER_DEVICE.ADDRESS_CALL' | translate }}</span>
                <span
                  ><span
                    *ngIf="!isEditing"
                    class="abilitation mr-2"
                    [ngClass]="{ green: extabControl.value }"
                  ></span>
                  <b *ngIf="!extabnameControl.value && !isEditing"> -- </b>
                  <input
                    id="extab"
                    [formControl]="extabnameControl"
                    [placeholder]="
                      !isEditing
                        ? ''
                        : ('USER_DEVICE.ADDRESS_CALL_DESC' | translate)
                    "
                    type="text"
                    class="custom-input d-inline"
                  />
                  <app-control-errors
                    id="extab-validator"
                    controlName="extabname"
                  ></app-control-errors>
                  <div class="switch mt-2" *ngIf="isEditing">
                    <app-switch
                      [control]="extabControl"
                      inputId="extab-checkbox"
                      [disableLogic]="extabControl.disabled"
                    ></app-switch>
                  </div>
                </span>
              </li>

              <!-- EMAIL -->
              <li *ngIf="typeControl.value !== 3 && typeControl.value !== 1">
                <span>{{ 'USER_DEVICE.EMAIL' | translate }}</span>
                <span>
                  <b *ngIf="!emailControl.value && !isEditing"> -- </b
                  ><input
                    id="email"
                    [formControl]="emailControl"
                    type="text"
                    class="custom-input"
                    [placeholder]="
                      !isEditing ? '' : ('USER_DEVICE.EMAIL' | translate)
                    "
                  />
                  <app-control-errors
                    id="email-validator"
                    controlName="email"
                    [customMessages]="{
                      pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
                    }"
                  ></app-control-errors>
                  <a
                    *ngIf="!isEditing && !isLoadingMail && emailControl.value"
                    id="send-email"
                    href="javascript:void(0)"
                    class="link-primary"
                    (click)="sendInvite()"
                  >
                    {{ 'GLOBAL.SEND' | translate }}
                  </a>

                  <i class="loading-icon material-icons" *ngIf="isLoadingMail"
                    >cached</i
                  >
                </span>
              </li>

              <!-- APP STATUS -->
              <li *ngIf="typeControl.value !== 3 && typeControl.value !== 1">
                <span>{{
                  'USER_DEVICE.APP_ACTIVATION_STATUS' | translate
                }}</span>
                <span
                  ><span
                    *ngIf="!isEditing"
                    class="abilitation"
                    [ngClass]="{ green: userDevice.vipertoken }"
                  ></span>
                  {{
                    (userDevice.vipertoken
                      ? 'USER_DEVICE.ACTIVE'
                      : 'USER_DEVICE.NOT_ACTIVE'
                    ) | translate
                  }}
                  <a
                    *ngIf="
                      !isEditing &&
                      !userDevice.actcode &&
                      !isLoadingAC &&
                      !userDevice.vipertoken
                    "
                    id="generate-code"
                    href="javascript:void(0)"
                    class="link-primary"
                    (click)="generateCode()"
                  >
                    {{ 'USER_DEVICE.GENERATE_ACTIVATION_CODE' | translate }}
                  </a>
                  <a
                    *ngIf="
                      !isEditing &&
                      !userDevice.actcode &&
                      !isLoadingAC &&
                      userDevice.vipertoken
                    "
                    id="reset-code"
                    href="javascript:void(0)"
                    class="link-primary"
                    (click)="resetCode()"
                  >
                    {{ 'USER_DEVICE.RESET' | translate }}
                  </a>
                  <i class="loading-icon material-icons" *ngIf="isLoadingAC"
                    >cached</i
                  >
                  <div id="act-code" *ngIf="userDevice.actcode && !isLoadingAC">
                    <b class="mt-1 mr-3"
                      >{{ 'USER_DEVICE.ACTIVATION_CODE' | translate }}:
                      {{ userDevice.actcode }}</b
                    >
                    <i
                      id="code-delete"
                      (click)="deleteActivationCode()"
                      class="material-icons green single-menu-icon"
                      >delete</i
                    >
                  </div></span
                >
              </li>

              <!-- ENABLING -->
              <li>
                <span>{{ 'USER_DEVICE.DEVICE_ENABLING' | translate }}</span>
                <span
                  ><span
                    *ngIf="!isEditing"
                    class="abilitation"
                    [ngClass]="{ green: enableControl.value }"
                  ></span>
                  {{
                    (enableControl.value
                      ? 'USER_DEVICE.ENABLED'
                      : 'USER_DEVICE.DISABLED'
                    ) | translate
                  }}</span
                >
                <div class="switch" *ngIf="isEditing">
                  <app-switch
                    [control]="enableControl"
                    inputId="backup"
                    [disableLogic]="enableControl.disabled"
                  ></app-switch>
                </div>
              </li>

              <!-- BACKUP -->
              <li *ngIf="typeControl.value === 2 && aptType !== 1">
                <span>{{ 'USER_DEVICE.BACKUP_PHONECALL' | translate }}</span>
                <span>
                  <span
                    *ngIf="!isEditing"
                    class="abilitation"
                    [ngClass]="{ green: backupControl.value }"
                  ></span>
                  <b>{{
                    (backupControl.value
                      ? 'USER_DEVICE.ENABLED'
                      : 'USER_DEVICE.DISABLED'
                    ) | translate
                  }}</b>
                </span>
                <div class="switch" *ngIf="isEditing">
                  <app-switch
                    [control]="backupControl"
                    inputId="backup"
                    [disableLogic]="
                      backupControl.disabled ||
                      (!backupControl.value && remainingLines === 0)
                    "
                  ></app-switch>
                </div>
              </li>

              <!-- PHONE BACKUP -->
              <li *ngIf="backupControl.value && typeControl.value !== 3">
                <span>{{ 'USER_DEVICE.PHONE_BACKUP' | translate }}</span>
                <span>
                  <b *ngIf="!phoneBackupControl.value && !isEditing"> -- </b
                  ><input
                    id="phone-backup"
                    [formControl]="phoneBackupControl"
                    [placeholder]="
                      !isEditing ? '' : ('USER_DEVICE.PHONE_BACKUP' | translate)
                    "
                    type="text"
                    class="custom-input"
                  />
                  <div
                    id="phone-backup-validator"
                    *ngIf="
                      phoneBackupControl.invalid &&
                      (phoneBackupControl.touched || phoneBackupControl.dirty)
                    "
                    class="input-field-error"
                  >
                    {{ 'USER_DEVICE.PHONE_VALIDATION_MESSAGE' | translate }}
                  </div>
                </span>
              </li>

              <!-- PHONE -->
              <li *ngIf="typeControl.value === 3">
                <span>{{ 'USER_DEVICE.PHONE' | translate }}</span>
                <span>
                  <b *ngIf="!phoneControl.value && !isEditing"> -- </b
                  ><input
                    id="phone"
                    [formControl]="phoneControl"
                    [placeholder]="
                      !isEditing ? '' : ('USER_DEVICE.PHONE' | translate)
                    "
                    type="text"
                    class="custom-input"
                  />
                  <app-control-errors
                    id="phone-validator"
                    controlName="sipnum"
                    [customMessages]="{
                      pattern:
                        'USER_DEVICE.PHONE_VALIDATION_MESSAGE' | translate
                    }"
                  ></app-control-errors>
                </span>
              </li>

              <!-- CLOUD SYNC -->
              <li *ngIf="typeControl.value !== 3 && typeControl.value !== 1">
                <span>{{ 'USER_DEVICE.CLOUD_SYNC' | translate }}</span>
                <span
                  ><span
                    class="abilitation"
                    [ngClass]="{ green: syncControl.value }"
                  ></span>
                  {{
                    (syncControl.value
                      ? 'USER_DEVICE.SYNCED'
                      : 'USER_DEVICE.NOT_SYNCED'
                    ) | translate
                  }}</span
                >
              </li>

              <!-- ID DEVICE -->
              <li>
                <span>{{ 'USER_DEVICE.USER_DEVICE_ID' | translate }}</span>
                <span>{{ userDevice.id }}</span>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-4">
      <div class="card-small mt-3" *ngIf="apt">
        <div class="card-header">
          <h4>{{ 'APARTMENT.REGISTRY' | translate }}</h4>
        </div>
        <div class="card-body py-2">
          <ul id="reference-info">
            <li>
              <b>{{ 'APARTMENT.APARTMENT_ID' | translate }}</b> <br />
              {{ apt.id }}
            </li>
            <li>
              <b>{{ 'APARTMENT.DESCRIPTION' | translate }}</b> <br />
              {{ apt.descr || '--' }}
            </li>
            <li *ngIf="!isSbc(device.resource.deviceModelId)">
              <b>{{ 'APARTMENT.EMAIL' | translate }}</b> <br />
              {{ apt.email || '--' }}
            </li>
            <li>
              <b>{{ 'APARTMENT.DEVICE' | translate }}</b> <br />
              {{ device.resource.name || '--' }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

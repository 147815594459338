import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, timer } from 'rxjs';
import { finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  GeneralCommandsService,
  SafeActionEmailAssignmentDto,
  UserAssignmentResponseDto
} from 'src/app/api/dpcp';
import {
  LicenseInstanceDto,
  LicenseInstanceManagementDeviceService
} from 'src/app/api/licenses';
import { CrudService } from 'src/app/api/mc';
import { SiteDto } from 'src/app/api/myportal/model/siteDto';
import { CCAPIHTTPServicerestOtpService } from 'src/app/api/otp';
import {
  AddElementDto,
  DeviceListElementDto,
  TreeService
} from 'src/app/api/tree';
import { AuthService } from 'src/app/core/auth.service';
import { MobileService } from 'src/app/core/mobile.service';
import { checkFormValidity, isSbc } from 'src/app/core/utils';
import { ConnectivityModalComponent } from 'src/app/shared/connectivity-modal/connectivity-modal.component';
import { SiteAssociationModalComponent } from 'src/app/shared/site-association-modal/site-association-modal.component';
import { CreateSiteModalComponent } from 'src/app/sites/create-site-modal/create-site-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-device-modal',
  templateUrl: './create-device-modal.component.html',
  styleUrls: ['./create-device-modal.component.scss']
})
export class CreateDeviceModalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  siteId?: string;
  isUnlockingDevice? = false;
  siteRootNote?: string;
  isLoading: boolean;
  isAssociating: boolean;
  sbcOffline = false;
  deviceFormGroup: UntypedFormGroup;
  deviceDescControl = new UntypedFormControl('', Validators.required);

  backupControl = new UntypedFormControl('', Validators.required);
  pswControl = new UntypedFormControl('', Validators.required);

  deviceCodeControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(32),
    Validators.minLength(32)
  ]);
  deviceOtpControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(5),
    Validators.minLength(5)
  ]);

  deviceCreated = false;
  deviceCreatedSafe = false;
  device: DeviceListElementDto;
  deviceCreatedEv = new EventEmitter<void>();

  safeDevice: DeviceListElementDto;
  detectedDevice: string;

  deviceTypeList = [
    {
      name: this.ts.instant('DEVICE.TYPE.FIRE'),
      type: 0
    },
    {
      name: this.ts.instant('DEVICE.TYPE.SAFE'),
      type: 1
    }
  ];
  deviceTypeControl = new UntypedFormControl(0, Validators.required);

  constructor(
    private modalRef: BsModalRef,
    private treeService: TreeService,
    private licenseService: LicenseInstanceManagementDeviceService,
    private mobileService: MobileService,
    private authService: AuthService,
    private crudService: CrudService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private otpService: CCAPIHTTPServicerestOtpService,
    private generalCommandsService: GeneralCommandsService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    this.deviceFormGroup = new UntypedFormGroup({
      deviceId32: this.deviceCodeControl,
      resourceContext: new UntypedFormControl('user')
    });

    this.deviceTypeControl.setValue(0);
    if (this.isUnlockingDevice) {
      this.deviceTypeControl.setValue(1);
      this.deviceTypeChanged({ type: 1 });
    }

    if (environment.client !== 'teletek') {
      this.deviceTypeList.push({
        name: this.ts.instant('DEVICE.TYPE.VIP'),
        type: 0
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.deviceCreatedSafe) {
      this.deviceCreatedEv.next();
    }
    (window as any).scanComplete = undefined;
  }

  deviceTypeChanged(e: any) {
    if (e.type === 0) {
      this.deviceFormGroup = new UntypedFormGroup({
        deviceId32: this.deviceCodeControl,
        resourceContext: new UntypedFormControl('user')
      });
    } else {
      this.deviceFormGroup = new UntypedFormGroup({
        otp: this.deviceOtpControl,
        resourceContext: new UntypedFormControl('user')
      });
    }
  }

  save() {
    if (checkFormValidity(this.deviceFormGroup)) {
      if (this.deviceTypeControl.value === 1) {
        this.isLoading = true;

        const safeData: SafeActionEmailAssignmentDto = {
          pin: this.deviceOtpControl.value,
          role: 'INSTALLER'
        };
        this.generalCommandsService.userAssignment(safeData).subscribe(
          (r: UserAssignmentResponseDto) => {
            this.continueSafeDeviceCreation(r);
          },
          (err) => {
            this.toastrService.clear();
            this.isLoading = false;
            if (
              err.error.ccapi_error_code &&
              err.error.ccapi_error_code === 8001
            ) {
              this.toastrService.error(
                this.ts.instant('DEVICE.SAFE.OTP_NOT_VALID'),
                this.ts.instant('GLOBAL.ERROR')
              );
            } else if (err.error.ccapi_error_code === 1000000114) {
              this.toastrService.error(
                this.ts.instant('DEVICE.SAFE.ROLE_OR_OTP_NOT_VALID'),
                this.ts.instant('GLOBAL.ERROR')
              );
            }
          }
        );
      } else {
        this.deviceCreated = true;
      }
    }
  }

  continueSafeDeviceCreation(r: UserAssignmentResponseDto) {
    const deviceId = r.deviceUuid;
    const deviceIdFinal = r.deviceAuthId;
    const data: any = {
      filter: {
        eventType: 'USER_ADD_ACKNOWLEDGMENT' as any,
        sourceDeviceId: deviceId,
        'parameters.otp': this.deviceOtpControl.value
      },
      sort: {
        date: -1 as any
      }
    };

    // POLLING
    let pollingSuccess = false;
    timer(1, 1000)
      .pipe(
        take(60),
        switchMap(() => this.crudService.read('messages', data)),
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.isLoading = false;
          if (!pollingSuccess) {
            this.toastrService.error(
              this.ts.instant('DEVICE.HTTP_ERROR_ASSOCIATION_TIMEOUT'),
              this.ts.instant('GLOBAL.ERROR')
            );
          }
        })
      )
      .subscribe((result: any) => {
        if (result.entities && result.entities?.length > 0) {
          if (result.entities[0].parameters?.outcome === 'true') {
            pollingSuccess = true;
            this.isLoading = true;
            // GET DEVICE INFO TO SHOW
            this.treeService
              .getUserResourceList(
                this.authService.getToken(),
                undefined,
                deviceIdFinal,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                ['pro_buildingmanager', 'pro_installer']
              )
              .subscribe((res: DeviceListElementDto[]) => {
                this.isLoading = false;
                this.deviceCreatedSafe = true;

                this.safeDevice = res[0];
                this.detectedDevice = r.modelDescription || '--';
              });
          } else {
            this.toastrService.error(
              this.ts.instant('DEVICE.HTTP_ERROR_ASSOCIATION'),
              this.ts.instant('GLOBAL.ERROR')
            );
            this.isLoading = false;
          }
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        }
      });
  }

  close() {
    this.modalRef.hide();
  }

  closeAndLoad() {
    this.deviceCreatedEv.next();
    this.close();
  }

  // create device from site creation
  createSite() {
    const initialState: Partial<CreateSiteModalComponent> = {};

    const modalRef = this.modalService.show(CreateSiteModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.siteCreated.subscribe((site: SiteDto) => {
      let deviceOpt: DeviceListElementDto = {};
      if (this.deviceCreatedSafe) {
        const deviceSafeInfo = {
          deviceId32: this.safeDevice.deviceId32,
          resourceContext: 'user',
          name: this.safeDevice.name,
          siteAuthenticationId: site.authenticationId,
          siteRootNode: site.rootNode
        };
        this.linkSafeDevice(deviceSafeInfo);
      } else {
        deviceOpt = {
          ...this.deviceFormGroup.value,
          name: this.deviceDescControl.value,
          siteAuthenticationId: site.authenticationId
        };

        this.treeService
          .addDeviceToResourceList(
            this.authService.getToken(),
            undefined,
            false,
            deviceOpt
          )
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            (res: DeviceListElementDto) => {
              if (isSbc(res.deviceModelId) && res.deviceModelId !== 'SBEn') {
                modalRef.hide();
                this.activateSbc(res);
                this.modalRef.hide();
                this.deviceCreatedEv.next();
              } else {
                this.toastrService.success(
                  this.ts.instant('DEVICE.DEVICE_CREATED'),
                  this.ts.instant('GLOBAL.SUCCESS')
                );
                modalRef.hide();
                this.modalRef.hide();
                this.deviceCreatedEv.next();
              }
            },
            (error) => {
              if (error.error_id === 'AUTH:RESLIST:ALREADY_LINKED') {
                this.toastrService.clear();
                this.toastrService.error(
                  this.ts.instant('DEVICE.HTTP_ERROR_ALREADY_LINKED'),
                  this.ts.instant('GLOBAL.ERROR')
                );
              }
            }
          );
      }
    });
  }

  // create device from site association
  openSiteAssociation() {
    let device = {};
    if (this.deviceCreatedSafe) {
      device = {
        deviceId32: this.safeDevice.deviceId32,
        resourceContext: 'user',
        name: this.safeDevice.name
      };
    } else {
      device = {
        ...this.deviceFormGroup.value,
        name: this.deviceDescControl.value
      };
    }
    const initialState: Partial<SiteAssociationModalComponent> = {
      device,
      safeDeviceCreation: this.deviceCreatedSafe
    };

    const modalRef = this.modalService.show(SiteAssociationModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.siteAssociated.subscribe(() => {
      this.toastrService.success(
        this.ts.instant('DEVICE.DEVICE_CREATED'),
        this.ts.instant('GLOBAL.SUCCESS')
      );
      this.modalRef.hide();
      modalRef.hide();
      this.deviceCreatedEv.next();
    });
  }

  // create device from site detail
  associateWithGivenSite() {
    this.isAssociating = true;
    let deviceOpt: DeviceListElementDto = {};
    if (this.deviceCreatedSafe) {
      const deviceSafeInfo = {
        deviceId32: this.safeDevice.deviceId32,
        resourceContext: 'user',
        name: this.safeDevice.name,
        siteAuthenticationId: this.siteId,
        siteRootNode: this.siteRootNote
      };
      this.linkSafeDevice(deviceSafeInfo);
    } else {
      deviceOpt = {
        ...this.deviceFormGroup.value,
        name: this.deviceDescControl.value,
        siteAuthenticationId: this.siteId
      };

      this.treeService
        .addDeviceToResourceList(
          this.authService.getToken(),
          undefined,
          false,
          deviceOpt
        )
        .pipe(finalize(() => (this.isAssociating = false)))
        .subscribe(
          (res: DeviceListElementDto) => {
            if (isSbc(res.deviceModelId) && res.deviceModelId !== 'SBEn') {
              this.activateSbc(res);
              this.modalRef.hide();
              this.deviceCreatedEv.next();
            } else {
              this.toastrService.success(
                this.ts.instant('DEVICE.DEVICE_CREATED'),
                this.ts.instant('GLOBAL.SUCCESS')
              );
              this.modalRef.hide();
              this.deviceCreatedEv.next();
            }
          },
          (error) => {
            if (error.error.error_id === 'AUTH:RESLIST:ALREADY_LINKED') {
              this.toastrService.clear();
              this.toastrService.error(
                this.ts.instant('DEVICE.HTTP_ERROR_ALREADY_LINKED'),
                this.ts.instant('GLOBAL.ERROR')
              );
            }
          }
        );
    }
  }

  codeRetrieved(res: string) {
    this.deviceCodeControl.setValue(res);
  }

  linkSafeDevice(device: any) {
    const dev: AddElementDto = {
      id32: device.deviceId32,
      type: 'DEVICE',
      siteAuthenticationId: device.siteAuthenticationId,
      label: device.name
    };
    this.treeService
      .addElements(device.siteRootNode, this.authService.getToken(), dev)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: DeviceListElementDto) => {
          if (isSbc(res.deviceModelId) && res.deviceModelId !== 'SBEn') {
            this.activateSbc(res);
            this.modalRef.hide();
            this.deviceCreatedEv.next();
          } else {
            this.toastrService.success(
              this.ts.instant('DEVICE.DEVICE_CREATED'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
            this.modalRef.hide();
            this.deviceCreatedEv.next();
          }
        },
        (error) => {
          if (error.error.error_id === 'AUTH:TREE:ALREADY_LINKED') {
            this.toastrService.clear();
            this.toastrService.error(
              this.ts.instant('DEVICE.HTTP_ERROR_ALREADY_LINKED_SITE'),
              this.ts.instant('GLOBAL.ERROR')
            );
          }
        }
      );
  }

  activateSbc(dev: DeviceListElementDto) {
    this.licenseService
      .getDeviceLicenseInstancesByReferencedLicenseUuid(
        environment.licenseId,
        dev.uuid!,
        ['EXPIRED', 'EXPIRING', 'ENABLED', 'DISABLED']
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: LicenseInstanceDto[]) => {
        if (res.length === 0) {
          const initialState: Partial<ConnectivityModalComponent> = {
            deviceId: dev.uuid,
            deviceAuthId: dev.authenticationId
          };

          const modalRef = this.modalService.show(ConnectivityModalComponent, {
            initialState,
            class: 'modal-lg',
            ignoreBackdropClick: true
          });
        }
      });
  }
}

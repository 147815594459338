/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SetZoneStateDto {
  /**
   * Zone index is an integer number indicating the index of the zone to disable in device’s zones list.
   */
  zoneIndex: number;
  /**
   * Represents the state that the zone should take
   */
  zoneState: SetZoneStateDto.ZoneStateEnum;
}
export namespace SetZoneStateDto {
  export type ZoneStateEnum = 'RESET_OR_DEACTIVATE' | 'SET_OR_ACTIVATE';
  export const ZoneStateEnum = {
    RESETORDEACTIVATE: 'RESET_OR_DEACTIVATE' as ZoneStateEnum,
    SETORACTIVATE: 'SET_OR_ACTIVATE' as ZoneStateEnum
  };
}

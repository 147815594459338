/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { CommandExecutionResultDto } from '../model/models';
import { DeviceDto } from '../model/models';
import { ErrorDto } from '../model/models';
import { SetDeviceStateDto } from '../model/models';

import { FIRE_BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  protected basePath =
    'https://usvc-preprod.cloud.comelitgroup.com/servicerest/fire';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(FIRE_BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Change device state
   * @param deviceUuid Device UUID
   * @param sSID Device SSID session identifier
   * @param setDeviceStateDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmdSetDeviceState(
    deviceUuid: string,
    sSID: number,
    setDeviceStateDto?: SetDeviceStateDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<CommandExecutionResultDto>;
  public cmdSetDeviceState(
    deviceUuid: string,
    sSID: number,
    setDeviceStateDto?: SetDeviceStateDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<CommandExecutionResultDto>>;
  public cmdSetDeviceState(
    deviceUuid: string,
    sSID: number,
    setDeviceStateDto?: SetDeviceStateDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<CommandExecutionResultDto>>;
  public cmdSetDeviceState(
    deviceUuid: string,
    sSID: number,
    setDeviceStateDto?: SetDeviceStateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling cmdSetDeviceState.'
      );
    }
    if (sSID === null || sSID === undefined) {
      throw new Error(
        'Required parameter sSID was null or undefined when calling cmdSetDeviceState.'
      );
    }

    let headers = this.defaultHeaders;
    if (sSID !== undefined && sSID !== null) {
      headers = headers.set('SSID', String(sSID));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.post<CommandExecutionResultDto>(
      `${this.configuration.basePath}/command/${encodeURIComponent(
        String(deviceUuid)
      )}/set-state/device`,
      setDeviceStateDto,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve information associated to devices.
   * @param deviceUuid Device UUID
   * @param id Device ID
   * @param sSID Device SSID session identifier
   * @param field List of fields (columns) to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevice(
    deviceUuid: string,
    id: number,
    sSID: number,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<DeviceDto>;
  public getDevice(
    deviceUuid: string,
    id: number,
    sSID: number,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<DeviceDto>>;
  public getDevice(
    deviceUuid: string,
    id: number,
    sSID: number,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<DeviceDto>>;
  public getDevice(
    deviceUuid: string,
    id: number,
    sSID: number,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling getDevice.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getDevice.'
      );
    }
    if (sSID === null || sSID === undefined) {
      throw new Error(
        'Required parameter sSID was null or undefined when calling getDevice.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (field) {
      field.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'field'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (sSID !== undefined && sSID !== null) {
      headers = headers.set('SSID', String(sSID));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<DeviceDto>(
      `${this.configuration.basePath}/real-time/${encodeURIComponent(
        String(deviceUuid)
      )}/device/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve information associated to devices.
   * @param deviceUuid Device UUID
   * @param sSID Device SSID session identifier
   * @param offset Starting offset - does not allow row(s) parameter
   * @param limit Maximum number of rows to retrieve - does not allow row(s) parameter
   * @param row List of rows to be retrieved - does not allow offset/limit parameters
   * @param field List of fields (columns) to be retrieved
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevices(
    deviceUuid: string,
    sSID: number,
    offset?: number,
    limit?: number,
    row?: Array<number>,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<Array<DeviceDto>>;
  public getDevices(
    deviceUuid: string,
    sSID: number,
    offset?: number,
    limit?: number,
    row?: Array<number>,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<Array<DeviceDto>>>;
  public getDevices(
    deviceUuid: string,
    sSID: number,
    offset?: number,
    limit?: number,
    row?: Array<number>,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<Array<DeviceDto>>>;
  public getDevices(
    deviceUuid: string,
    sSID: number,
    offset?: number,
    limit?: number,
    row?: Array<number>,
    field?: Array<
      | 'NAME'
      | 'LOOP'
      | 'ADDRESS'
      | 'TYPE'
      | 'STATUS1'
      | 'STATUS2'
      | 'ZONE'
      | 'ZONE_NAME'
      | 'COMMUNICATION_QUALITY'
      | 'SERIAL'
      | 'ZONAL_ADDRESS'
    >,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (deviceUuid === null || deviceUuid === undefined) {
      throw new Error(
        'Required parameter deviceUuid was null or undefined when calling getDevices.'
      );
    }
    if (sSID === null || sSID === undefined) {
      throw new Error(
        'Required parameter sSID was null or undefined when calling getDevices.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (offset !== undefined && offset !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>offset,
        'offset'
      );
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>limit,
        'limit'
      );
    }
    if (row) {
      row.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'row'
        );
      });
    }
    if (field) {
      field.forEach((element) => {
        queryParameters = this.addToHttpParams(
          queryParameters,
          <any>element,
          'field'
        );
      });
    }

    let headers = this.defaultHeaders;
    if (sSID !== undefined && sSID !== null) {
      headers = headers.set('SSID', String(sSID));
    }

    // authentication (ccs_token) required
    if (this.configuration.apiKeys) {
      const key: string | undefined =
        this.configuration.apiKeys['ccs_token'] ||
        this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(
        httpHeaderAccepts
      );
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType = 'text';
    }

    return this.httpClient.get<Array<DeviceDto>>(
      `${this.configuration.basePath}/real-time/${encodeURIComponent(
        String(deviceUuid)
      )}/devices`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

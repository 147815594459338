/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IOModulesStatus2BitMaskDto } from './iOModulesStatus2BitMaskDto';
import { IOModulesChannelBitMaskDto } from './iOModulesChannelBitMaskDto';
import { IOModulesStatus1BitMaskDto } from './iOModulesStatus1BitMaskDto';

export interface IOModulesDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Loop is an integer value containing the loop index the module belongs to.
   */
  loop?: number;
  /**
   * Address is an integer value containing the index of module inside its loop.
   */
  address?: number;
  /**
   * Type is an integer defining the type of the module.
   */
  type?: IOModulesDto.TypeEnum;
  status1?: IOModulesStatus1BitMaskDto;
  status2?: IOModulesStatus2BitMaskDto;
  /**
   * Zone is an integer value containing the zone index the module belongs to.
   */
  zone?: number;
  /**
   * Zone name is a string containing the zone name to whom device belongs.
   */
  zoneName?: string;
  /**
   * Number of Channels is an integer value containing the number of channels the module supports, could be 1 or 4.
   */
  channelsNr?: number;
  channel1Properties?: IOModulesChannelBitMaskDto;
  channel2Properties?: IOModulesChannelBitMaskDto;
  channel3Properties?: IOModulesChannelBitMaskDto;
  channel4Properties?: IOModulesChannelBitMaskDto;
  /**
   * Channels Name is a string containing the name of the channel.
   */
  channel1Name?: string;
  /**
   * Channels Name is a string containing the name of the channel.
   */
  channel2Name?: string;
  /**
   * Channels Name is a string containing the name of the channel.
   */
  channel3Name?: string;
  /**
   * Channels Name is a string containing the name of the channel.
   */
  channel4Name?: string;
}
export namespace IOModulesDto {
  export type TypeEnum =
    | 'MIO40'
    | 'MIO22'
    | 'MIO22M'
    | 'MIO04'
    | 'MOUT'
    | 'MOUT240'
    | 'MINP'
    | 'MIMIC'
    | 'MINI_OUT'
    | 'MI420'
    | 'COM_41IOM040'
    | 'COM_41IOM022'
    | 'COM_41IOM122'
    | 'COM_41IOM004'
    | 'COM_41IOM000'
    | 'COM_41IOM000_240'
    | 'COM_41IOM010'
    | 'COM_41LED032'
    | 'COM_41IOM001'
    | 'COM_MI420';
  export const TypeEnum = {
    MIO40: 'MIO40' as TypeEnum,
    MIO22: 'MIO22' as TypeEnum,
    MIO22M: 'MIO22M' as TypeEnum,
    MIO04: 'MIO04' as TypeEnum,
    MOUT: 'MOUT' as TypeEnum,
    MOUT240: 'MOUT240' as TypeEnum,
    MINP: 'MINP' as TypeEnum,
    MIMIC: 'MIMIC' as TypeEnum,
    MINIOUT: 'MINI_OUT' as TypeEnum,
    MI420: 'MI420' as TypeEnum,
    COM41IOM040: 'COM_41IOM040' as TypeEnum,
    COM41IOM022: 'COM_41IOM022' as TypeEnum,
    COM41IOM122: 'COM_41IOM122' as TypeEnum,
    COM41IOM004: 'COM_41IOM004' as TypeEnum,
    COM41IOM000: 'COM_41IOM000' as TypeEnum,
    COM41IOM000240: 'COM_41IOM000_240' as TypeEnum,
    COM41IOM010: 'COM_41IOM010' as TypeEnum,
    COM41LED032: 'COM_41LED032' as TypeEnum,
    COM41IOM001: 'COM_41IOM001' as TypeEnum,
    COMMI420: 'COM_MI420' as TypeEnum
  };
}

import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { DeviceListElementDto } from '../api/tree';

export interface RealTimeType {
  type: number;
  name: string;
}

export function markFormGroupTouched(formGroup: UntypedFormGroup) {
  Object.values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

export function checkFormValidity(form: UntypedFormGroup): boolean {
  if (form.invalid) {
    markFormGroupTouched(form);
    setTimeout(() => {
      let firstInvalidEl = (document.querySelector(
        'input.ng-invalid.ng-touched'
      ) ||
        document.querySelector(
          'ng-select.ng-invalid.ng-touched'
        )) as HTMLElement | null;

      if (firstInvalidEl) {
        firstInvalidEl.scrollIntoView({ block: 'center' });
        window.scrollBy(0, -100);
        firstInvalidEl.focus();
      }
    });
    return false;
  } else {
    return true;
  }
}

export function checkIfUsed(list: string[]): ValidatorFn {
  return (formControl: AbstractControl) => {
    let validity: ValidationErrors | null = null;

    const control = formControl;

    if (control === null) {
      return null;
    }
    if (list.indexOf(control.value) !== -1) {
      validity = { used: true };
    }

    control.setErrors(validity);
    return validity;
  };
}

export function mustNotBeAlreadyAnUser(users: string[]): ValidatorFn {
  return (formControl: AbstractControl) => {
    let validity: ValidationErrors | null = null;

    const control = formControl;

    if (control === null) {
      return null;
    }
    if (users.indexOf(control.value) !== -1) {
      validity = { alreadyUser: true };
    }

    control.setErrors(validity);
    return validity;
  };
}
export function checkIfAlreadyPresent(tags: string[]): ValidatorFn {
  return (formControl: AbstractControl) => {
    let validity: ValidationErrors | null = null;

    if (formControl === null) {
      return null;
    }

    if (formControl.parent) {
      let addressesCon = (formControl.parent as UntypedFormGroup).controls['addresses']
        .value;

      if (addressesCon.indexOf(formControl.value) !== -1) {
        validity = { alreadyPresent: true };
      }
    }

    formControl.setErrors(validity);
    return validity;
  };
}

export function mustBeDiffFromOwner(currentOwner: string): ValidatorFn {
  return (formControl: AbstractControl) => {
    let validity: ValidationErrors | null = null;

    const control = formControl;

    if (control === null) {
      return null;
    }
    if (control.value === currentOwner) {
      validity = { sameOwner: true };
    }

    control.setErrors(validity);
    return validity;
  };
}

export function mobileAndTabletCheck(): boolean {
  return (
    /MyComelit\/1.0.0/i.test(navigator.userAgent) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
}

export let isMobile: boolean = false;
export let isApp: boolean = false;
export function appAndMobileCheck() {
  isMobile =
    window.innerWidth < 768 ||
    /MyComelit\/1.0.0/i.test(navigator.userAgent) ||
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    );

  isApp = /MyComelit\/1.0.0/i.test(navigator.userAgent);
}

export function requiredAndTrimmed(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return !Boolean(control.value?.trim()) ? { required: true } : null;
  };
}

export function notCurrentID32(val: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value === val ? { sameId: true } : null;
  };
}
export function notInResourceList(
  val: string,
  list: DeviceListElementDto[]
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value === val
      ? { sameId: true }
      : list.findIndex((x) => x.deviceId32 === control.value) !== -1
      ? { alreadyLinked: true }
      : null;
  };
}

export function ifComelitIsRequired(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let validity: ValidationErrors | null = null;

    if (control.parent) {
      let comelitControl = (control.parent as UntypedFormGroup).controls['sendComelit']
        .value;

      if (!comelitControl) {
        return null;
      }

      if (!Boolean(control.value?.trim())) {
        validity = {
          required: true
        };
      }
    }
    return validity;
  };
}

export function ifUserPresentIsRequired(): ValidatorFn {
  return (group: AbstractControl): { [key: string]: boolean } | null => {
    let validity: ValidationErrors | null = null;

    const nameControl = group.get('name');
    const surnameControl = group.get('surname');
    const emailControl = group.get('email');

    if (!nameControl || !surnameControl || !emailControl) {
      return null;
    }
    emailControl.clearValidators();
    if (nameControl.value || surnameControl.value || emailControl.value) {
      emailControl.setValidators(
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
      );
      if (
        !Boolean(nameControl.value?.trim()) ||
        !Boolean(surnameControl.value?.trim()) ||
        !Boolean(emailControl.value?.trim())
      ) {
        validity = {
          required: true
        };
      }
    }

    return validity;
  };
}

export function isSafeModel(system: string | undefined): boolean {
  return (system || '') === 'SAFE';
}

export function isFireModel(system: string | undefined): boolean {
  return (system || '') === 'FIRE';
}

export function isMugModel(system: string | undefined): boolean {
  return (system || '') !== 'FIRE' && (system || '') !== 'SAFE';
}

export function callAngularFunction() {
  (window as any).angularComponentReference.zone.run(() => {
    (window as any).angularComponentReference.loadAngularFunction();
  });
}

export function isSbc(modelId: string | undefined) {
  return (modelId || '') === 'SBEx' || (modelId || '') === 'SBEn';
}

export function isUT(modelId: string | undefined) {
  return (modelId || '') === 'UlTo' || (modelId || '') === 'Udir';
}

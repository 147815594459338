/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.4+develop.011
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Account Linking DTO
 */
export interface AccountLinkingDto {
  /**
   * Zone in which the lambda function is located
   */
  lambda_zone: string;
  /**
   * Authorization code
   */
  auth_code: string;
  /**
   * Redirect URI
   */
  redirect_uri?: string;
}

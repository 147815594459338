/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OutputStatusBitMaskDto } from './outputStatusBitMaskDto';
import { OutputDtoOutputSounderDelay } from './outputDtoOutputSounderDelay';

export interface OutputDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Name is a string that contains name visualized for the output peripheral.
   */
  name?: string;
  /**
   * Peripheral address is an integer value containing the address of the peripheral.
   */
  address?: OutputDto.AddressEnum;
  status?: OutputStatusBitMaskDto;
  outputSounderDelay?: OutputDtoOutputSounderDelay;
  /**
   * Software version is a string containing the version running on output peripheral.
   */
  swVersion?: string;
}
export namespace OutputDto {
  export type AddressEnum =
    | 'PERI_ID_MAIN_BOARD'
    | 'PERI_ID_POWER_SUPPLY'
    | 'PERI_ID_OUTPUTS1'
    | 'PERI_ID_LOOP_1'
    | 'PERI_ID_LOOP_2'
    | 'PERI_ID_LOOP_3'
    | 'PERI_ID_LOOP_4'
    | 'PERI_ID_LOOP_5'
    | 'PERI_ID_LOOP_6'
    | 'PERI_ID_LOOP_7'
    | 'PERI_ID_LOOP_8'
    | 'PERI_ID_POWER_SUPPLY_EXT'
    | 'PERI_ID_OUTPUTS2';
  export const AddressEnum = {
    MAINBOARD: 'PERI_ID_MAIN_BOARD' as AddressEnum,
    POWERSUPPLY: 'PERI_ID_POWER_SUPPLY' as AddressEnum,
    OUTPUTS1: 'PERI_ID_OUTPUTS1' as AddressEnum,
    LOOP1: 'PERI_ID_LOOP_1' as AddressEnum,
    LOOP2: 'PERI_ID_LOOP_2' as AddressEnum,
    LOOP3: 'PERI_ID_LOOP_3' as AddressEnum,
    LOOP4: 'PERI_ID_LOOP_4' as AddressEnum,
    LOOP5: 'PERI_ID_LOOP_5' as AddressEnum,
    LOOP6: 'PERI_ID_LOOP_6' as AddressEnum,
    LOOP7: 'PERI_ID_LOOP_7' as AddressEnum,
    LOOP8: 'PERI_ID_LOOP_8' as AddressEnum,
    POWERSUPPLYEXT: 'PERI_ID_POWER_SUPPLY_EXT' as AddressEnum,
    OUTPUTS2: 'PERI_ID_OUTPUTS2' as AddressEnum
  };
}

import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { DeviceStatusDto, StatusAndConfigurationService } from '../api/mugconf';
import { DeviceListElementDto, TreeService } from '../api/tree';
import { AuthService } from '../core/auth.service';
import { DeviceCompleteDto } from '../core/models';

export type DeviceListDto = {
  count: number;
  values: DeviceCompleteDto[];
};

@Injectable({
  providedIn: 'root'
})
export class DevicesListService {
  devices$ = new BehaviorSubject<DeviceCompleteDto[]>([]);
  devicesNEW$ = new Subject<DeviceListDto>();
  deviceLinkUpdated = new EventEmitter<void>();
  deviceListUpdated = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private treeService: TreeService,
    private statusConfService: StatusAndConfigurationService
  ) {}

  loadDevicesNEW(
    siteId?: string,
    noStatus?: boolean,
    onlyAnomalies?: boolean,
    top?: number,
    offset?: number,
    sort?: any,
    filter?: string
  ) {
    const sortMap: any = {
      name: 'DESCRIPTION',
      deviceModelDescription: 'MODEL',
      status: 'STATUS',
      serviceStatus: 'SERVICE_STATUS',
      currentFWVersion: 'FW_VER',
      siteName: 'SITE',
      ownerUserIdentifier: 'OWNER'
    };

    const obs = forkJoin([
      this.treeService.getUserResourceListCount(
        this.authService.getToken(),
        siteId || undefined,
        filter || undefined,
        !onlyAnomalies
      ),
      this.treeService.getUserResourceList(
        this.authService.getToken(),
        siteId || undefined,
        undefined,
        top || undefined,
        offset || undefined,
        sort ? sortMap[sort.prop] : undefined,
        sort ? sort.dir.toUpperCase() : undefined,
        filter || undefined,
        !noStatus,
        !onlyAnomalies,
        ['pro_buildingmanager', 'pro_installer']
      )
    ]);

    obs.subscribe(([count, val]) => {
      const devicesUuid = val.map((x) => x.uuid!);
      let devicesComplete: DeviceCompleteDto[] = [];
      if (devicesUuid.length > 0 && !noStatus) {
        this.statusConfService
          .getDevicesStatus(this.authService.getToken(), undefined, false, {
            devicesUuid
          })
          .subscribe((statuses: Array<DeviceStatusDto>) => {
            val.forEach((element) => {
              const currDevice = statuses.find((x) => x.uuid === element.uuid);
              devicesComplete.push({
                status: currDevice || {},
                resource: element,
                id: element.authenticationId!
              });
            });
            this.devicesNEW$.next({
              count: count.count || 0,
              values: devicesComplete || []
            });
          });
      } else {
        this.devicesNEW$.next({
          count: count.count || 0,
          values:
            val.map((x) => {
              return { status: {}, resource: x, id: x.authenticationId! };
            }) || []
        });
      }
    });
  }

  loadDevicesOLD(siteId?: string) {
    this.treeService
      .getUserResourceList(this.authService.getToken(), siteId)
      .subscribe((res: DeviceListElementDto[]) => {
        const devicesUuid = res.map((x) => x.uuid!);
        let devicesComplete: DeviceCompleteDto[] = [];
        if (devicesUuid.length > 0) {
          this.statusConfService
            .getDevicesStatus(this.authService.getToken(), undefined, false, {
              devicesUuid
            })
            .subscribe((statuses: Array<DeviceStatusDto>) => {
              res.forEach((element) => {
                const currDevice = statuses.find(
                  (x) => x.uuid === element.uuid
                );
                devicesComplete.push({
                  status: currDevice || {},
                  resource: element,
                  id: element.authenticationId!
                });
              });

              this.devices$.next(devicesComplete);
            });
        } else {
          this.devices$.next([]);
        }
      });
  }
}

/**
 * Authentication - Tree Management API
 * API to interact with authentication service to manage the container/device tree
 *
 * The version of the OpenAPI document: 1.7.4+develop.011
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Details about sharing roles to a new user
 */
export interface ShareRoleDto {
  /**
   * Destination user identifier (email)
   */
  destinationUserUuid: string;
  /**
   * List of roles to be shared
   */
  roleNames: Array<string>;
}

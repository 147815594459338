/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RemoveDeviceFromStatisticsDto {
  /**
   * Loop index is an integer value containing the index of the loop the device belongs to in loops list
   */
  loopIndex: number;
  /**
   * Address is an integer value containing the index of device inside its loop
   */
  address: number;
}

import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GeneralCommandsService,
  RealtimeUserAccountsService,
  SafeUserAccountResponseDto
} from 'src/app/api/dpcp';
import { LogoutService } from 'src/app/api/dpcpf';
import {
  LicenseInstanceDto,
  LicenseInstanceManagementDeviceService
} from 'src/app/api/licenses';
import { DeviceAlertDto } from 'src/app/api/mugconf';
import { GroupResponseDtoList, GroupsService } from 'src/app/api/myportal';
import { OptionalIDService } from 'src/app/api/optid';
import { DeviceListElementDto, TreeService } from 'src/app/api/tree';
import { AuthService } from 'src/app/core/auth.service';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import {
  isMobile,
  isMugModel,
  isSafeModel,
  isSbc,
  isUT
} from 'src/app/core/utils';
import { CreateDeviceModalComponent } from 'src/app/devices/create-device-modal/create-device-modal.component';
import { DeviceDetailService } from 'src/app/devices/device-detail/device-detail.service';
import { EditDeviceModalComponent } from 'src/app/devices/edit-device-modal/edit-device-modal.component';
import { ReplaceDeviceModalComponent } from 'src/app/devices/replace-device-modal/replace-device-modal.component';
import { environment } from 'src/environments/environment';
import { AlertCodeType, DeviceCompleteDto } from '../../core/models';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { DeviceListDto, DevicesListService } from '../devices-list.service';
import { PinModalComponent } from '../pin-modal/pin-modal.component';
import { SearchService } from '../search-input/search.service';
import { SiteAssociationModalComponent } from '../site-association-modal/site-association-modal.component';
import { UserRole } from '../utils/users';

@Component({
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.scss']
})
export class DevicesTableComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() siteId: string | undefined;
  @Input() siteRootNode?: string | undefined;
  @Input() onlyAnomalies: boolean;
  @Input() hidePagination: boolean;
  canAct = false;
  _selectionType: string;
  ssid: number | undefined;
  @Input() set selectionType(v: string) {
    this._selectionType = v;
    this.selectedHandler = new SelectedItemsHandler<DeviceCompleteDto>('id');
    this.selected = [];
  }

  deviceUser: SafeUserAccountResponseDto;

  dataSource = new ClientFiltrableDataSource<DeviceCompleteDto>();
  columns: TableColumn[] = [];

  selectedHandler: SelectedItemsHandler<DeviceCompleteDto>;
  selected: string[];
  userRoleForSelectedSite: UserRole | undefined;
  deviceHasConn = true;

  @ViewChild('dropdown', { static: false }) dropdown: ElementRef<
    BsDropdownDirective
  >;

  get isMobile() {
    return isMobile;
  }

  @ViewChild('nameTpl', { static: true }) nameTpl: ElementRef<any>;
  @ViewChild('menuTpl', { static: true }) menuTpl: ElementRef<any>;
  @ViewChild('ownerTpl', { static: true }) ownerTpl: ElementRef<any>;
  @ViewChild('modelTpl', { static: true }) modelTpl: ElementRef<any>;
  @ViewChild('anomaliesTpl', { static: true }) anomaliesTpl: ElementRef<any>;
  @ViewChild('connectionTpl', { static: true }) connectionTpl: ElementRef<any>;
  @ViewChild('firmwareTpl', { static: true }) firmwareTpl: ElementRef<any>;
  @ViewChild('siteTpl', { static: true }) siteTpl: ElementRef<any>;
  @ViewChild('actionsTpl', { static: true }) actionsTpl: ElementRef<
    HTMLElement
  >;

  isSafeModel = isSafeModel;
  isSbc = isSbc;
  isUT = isUT;
  isMugModel = isMugModel;

  findSabotageOrAlerts(alerts: DeviceAlertDto[], searchVal: AlertCodeType) {
    return (
      (alerts || []).findIndex(
        (alert: DeviceAlertDto) =>
          alert.code && alert.code.indexOf(searchVal) !== -1
      ) !== -1
    );
  }

  limit = 10;
  page = 0;
  order: {} | undefined;
  filter: string | undefined;
  total = 10;

  constructor(
    private ts: TranslateService,
    public authService: AuthService,
    private logoutService: LogoutService,
    private treeService: TreeService,
    private optionalIdService: OptionalIDService,
    private deviceDetailService: DeviceDetailService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private devicesService: DevicesListService,
    private groupsService: GroupsService,
    private generalCommService: GeneralCommandsService,
    private userAccService: RealtimeUserAccountsService,
    private searchService: SearchService,
    private licenseService: LicenseInstanceManagementDeviceService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.devicesService.devices$.next([]);
  }

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('DEVICE.DEVICE_NAME'),
        cellTemplate: this.nameTpl,
        canAutoResize: true,
        cellClass: 'flex',
        resizeable: false
      },
      {
        prop: 'deviceModelDescription',
        name: this.ts.instant('DEVICE.MODEL'),
        cellTemplate: this.modelTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        prop: 'status',
        name: this.ts.instant('DEVICE.CONNECTION'),
        cellTemplate: this.connectionTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        prop: 'serviceStatus',
        name: this.ts.instant('DEVICE.STATUS'),
        cellTemplate: this.anomaliesTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        prop: 'currentFWVersion',
        name: this.ts.instant('DEVICE.FIRMWARE'),
        cellTemplate: this.firmwareTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        name: this.ts.instant('DEVICE.OWNER'),
        prop: 'ownerUserIdentifier',
        cellTemplate: this.ownerTpl,
        canAutoResize: true,
        resizeable: false
      },
      {
        name: '',
        cellTemplate: this.actionsTpl,
        sortable: false,
        canAutoResize: true
      },
      {
        name: this.ts.instant('GLOBAL.ACTIONS'),
        cellTemplate: this.menuTpl,
        canAutoResize: true,
        resizeable: false,
        width: 100,
        sortable: false
      }
    ];

    if (!this.siteId) {
      this.columns.splice(5, 0, {
        prop: 'siteName',
        name: this.ts.instant('DEVICE.SITE'),
        resizeable: false,
        cellTemplate: this.siteTpl
      });
    }

    this.searchService.searchValueChanged
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v: string) => {
        this.filter = v.toLowerCase();
        this.loadDevices();
      });

    this.dataSource.onTableSort = (v) => {
      this.order = v.sorts[0];
      this.loadDevices();
    };

    this.devicesService.devicesNEW$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r: DeviceListDto) => {
        this.total = r.count || 10;
        this.dataSource.setItems(r.values);
        this.dataSource.loadData();

        if (this.siteId || this.onlyAnomalies) {
          this.devicesService.deviceListUpdated.next();
        }
      });
    this.loadDevices();
  }

  pageChange(ev: any) {
    this.page = ev.page - 1;
    this.loadDevices();
  }

  loadDevices() {
    this.devicesService.loadDevicesNEW(
      this.siteId,
      false,
      !this.onlyAnomalies,
      this.limit,
      this.limit * this.page,
      this.order,
      this.filter
    );
  }

  hasStatus(device: DeviceCompleteDto) {
    return device.status.hasOwnProperty('status');
  }

  editDevice(device: DeviceCompleteDto) {
    const initialState: Partial<EditDeviceModalComponent> = {
      device: device,
      ssid: this.ssid
    };

    const modalRef = this.modalService.show(EditDeviceModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.deviceUpdated.subscribe(() => {
      if (isSafeModel(device.resource.system)) {
        this.deviceDetailService.logout(device.resource.uuid!);
      }
      this.loadDevices();
    });
  }

  retrieveSiteUserInfo(e: any, device: DeviceCompleteDto) {
    this.deviceHasConn = true;
    if (e) {
      this.canAct = false;
      this.groupsService
        .getGroups(
          this.authService.getToken(),
          undefined,
          undefined,
          device.resource.siteAuthenticationId,
          true
        )
        .subscribe((res: GroupResponseDtoList) => {
          if (res.groups && res.groups.length > 0) {
            this.userRoleForSelectedSite = res.groups[0].name?.split(
              '_'
            )[0] as UserRole;
          }

          if (this.isSafeModel(device.resource.system!)) {
            this.deviceDetailService
              .login(device.resource.uuid!)
              .subscribe((ssid: number) => {
                this.ssid = ssid;
                this.userAccService
                  .getUserAccountList(device.resource.uuid!, this.ssid!)
                  .subscribe((res: any) => {
                    this.canAct = true;
                    this.deviceUser = res.find(
                      (x: SafeUserAccountResponseDto) =>
                        x.email === this.authService.getEmail()
                    );
                  });
              });
          } else if (isSbc(device.resource.deviceModelId)) {
            this.licenseService
              .getDeviceLicenseInstancesByReferencedLicenseUuid(
                environment.licenseId,
                device.resource.uuid!,
                ['EXPIRED', 'EXPIRING', 'ENABLED', 'DISABLED']
              )
              .subscribe(
                (res: LicenseInstanceDto[]) => {
                  this.deviceHasConn = res.length !== 0;
                  this.canAct = true;
                },
                () => {
                  this.deviceHasConn = false;
                }
              );
          } else {
            this.canAct = true;
          }
        });
    }
  }

  unlinkDevice(device: DeviceCompleteDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('DEVICE.UNLINK_DEVICE'),
      description: this.ts.instant('DEVICE.UNLINK_DEVICE_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState,
      ignoreBackdropClick: true
    });

    modalRef.content!.confirmAction.subscribe(() => {
      this.treeService
        .unlinkDevice(device.resource.uuid!, this.authService.getToken())
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('DEVICE.UNLINKED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.deviceDetailService.logout(device.resource.uuid!);
          this.loadDevices();
        });
    });
  }

  deleteDevice(device: DeviceCompleteDto) {
    const initialState: Partial<ConfirmModalComponent> = {
      title: this.ts.instant('DEVICE.DELETE_DEVICE'),
      description: this.ts.instant('DEVICE.DELETE_DEVICE_DESC')
    };

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState,
      ignoreBackdropClick: true
    });

    modalRef.content!.confirmAction.subscribe(() => {
      if (isSafeModel(device.resource.system)) {
        this.manageSafeDevice(device, modalRef);
      } else {
        // if (isUT(device.deviceModelId)) {
        //   this.optionalIdService
        //     .deleteOptionalId(
        //       this.authService.getToken(),
        //       device.uuid!,
        //       'BT_MAC'
        //     )
        //     .subscribe(() => {
        //       this.manageVipDevice(device, modalRef);
        //     });
        // } else {
        // }
        this.manageVipDevice(device, modalRef);
      }
    });
  }

  manageVipDevice(device: DeviceCompleteDto, modalRef: BsModalRef) {
    if (device.resource.siteAuthenticationId) {
      this.treeService
        .unlinkDevice(device.resource.uuid!, this.authService.getToken())
        .subscribe(() => {
          if (
            device.resource.ownerUserIdentifier === this.authService.getEmail()
          ) {
            this.unlinkFromResourceList(device, modalRef);
          } else {
            modalRef.hide();
            this.toastr.success(
              this.ts.instant('DEVICE.DEVICE_DELETED'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
            this.loadDevices();
          }
        });
    } else {
      this.unlinkFromResourceList(device, modalRef);
    }
  }

  manageSafeDevice(device: DeviceCompleteDto, modalRef: BsModalRef) {
    const initialState: Partial<PinModalComponent> = {
      device,
      ssid: this.ssid
    };
    const modalRef2 = this.modalService.show(PinModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-md'
    });
    modalRef2.content!.pinInserted.subscribe((ssid: number | undefined) => {
      modalRef.hide();
      if (device.resource.siteAuthenticationId) {
        this.treeService
          .unlinkDevice(device.resource.uuid!, this.authService.getToken())
          .subscribe(() => {
            this.unlinkFromResourceList(device, modalRef2, ssid);
          });
      } else {
        this.unlinkFromResourceList(device, modalRef2, ssid);
      }
    });
  }

  unlinkFromResourceList(
    device: DeviceCompleteDto,
    modalRef: BsModalRef,
    ssid?: number
  ) {
    if (isSafeModel(device.resource.system)) {
      this.generalCommService
        .userAccountRemove(device.resource.uuid!, ssid!, {
          userId: this.deviceUser.id,
          role: 'INSTALLER'
        })
        .subscribe((r) => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('DEVICE.DEVICE_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.deviceDetailService.logout(device.resource.uuid!);
          this.logoutService.logout(device.resource.uuid!, ssid!).subscribe();

          this.loadDevices();
        });
    } else {
      this.treeService
        .removeResourceListEntry(
          device.resource.authenticationId!,
          this.authService.getToken()
        )
        .subscribe(() => {
          modalRef.hide();
          this.toastr.success(
            this.ts.instant('DEVICE.DEVICE_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          this.loadDevices();
        });
    }
  }

  openUnlock() {
    const initialState: Partial<CreateDeviceModalComponent> = {
      isUnlockingDevice: true,
      siteId: this.siteId,
      siteRootNote: this.siteRootNode
    };

    const modalRef2 = this.modalService.show(CreateDeviceModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef2.content!.deviceCreatedEv.subscribe(() => {
      this.loadDevices();
    });
  }

  isInPrivacyMode(device: DeviceCompleteDto) {
    return (
      device.resource.privacySafeModeActive &&
      device.resource.privacySafeModeEnabled
    );
  }

  getFwVersion(device: DeviceCompleteDto) {
    // if (device.status.currentVersions) {
    //   return isSafeModel(device.status.system) ||
    //     isFireModel(device.status.system)
    //     ? device.status.currentVersions!['UAPP.ver_string'] || '--'
    //     : device.status.currentVersions!['RtFS.ver_string'] || '--';
    // }
    // return '--';
    return device.resource.currentFWVersion || '--';
  }

  associateSite(device: DeviceCompleteDto) {
    const initialState: Partial<SiteAssociationModalComponent> = {
      device: device.resource,
      safeDeviceCreation: true,
      addSmall: true
    };

    const modalRef = this.modalService.show(SiteAssociationModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });

    modalRef.content!.siteAssociated.subscribe(() => {
      this.toastr.success(
        this.ts.instant('DEVICE.DEVICE_ASSOCIATED'),
        this.ts.instant('GLOBAL.SUCCESS')
      );
      modalRef.hide();
      this.loadDevices();
    });
  }

  replaceDevice(device: DeviceCompleteDto) {
    this.treeService
      .getUserResourceList(
        this.authService.getToken(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        false,
        undefined,
        ['pro_buildingmanager', 'pro_installer']
      )
      .subscribe((r: DeviceListElementDto[]) => {
        const devices = r;

        const initialState: Partial<ReplaceDeviceModalComponent> = {
          device,
          devices
        };
        const modalRef = this.modalService.show(ReplaceDeviceModalComponent, {
          initialState,
          ignoreBackdropClick: true,
          class: 'modal-lg'
        });
        modalRef.content!.deviceReplaced.subscribe(() => {
          this.toastr.success(
            this.ts.instant('DEVICE.DEVICE_REPLACED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          modalRef.hide();
          this.loadDevices();
        });
      });
  }
}

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeviceStatisticsDto {
  /**
   * Identifier
   */
  id?: number;
  /**
   * Loop is an integer value containing the loop index the device belongs to.
   */
  loop?: number;
  /**
   * Address is an integer value containing the index of device inside its loop.
   */
  address?: number;
  /**
   * Event text is a string containing the textual description of the event.
   */
  type?: DeviceStatisticsDto.TypeEnum;
  /**
   * Zone is an integer value containing the zone index the device belongs to.
   */
  zone?: number;
  /**
   * Number of alarms.
   */
  alarmsNr?: number;
}
export namespace DeviceStatisticsDto {
  export type TypeEnum =
    | 'T110'
    | 'T110_IS'
    | 'S130'
    | 'S130_IS'
    | 'M140'
    | 'M140_IS'
    | 'COM_41RCS100'
    | 'COM_41RCS100_IS'
    | 'COM_NONE_41RFU100'
    | 'COM_41RFU100'
    | 'COM_NONE_41RML100'
    | 'COM_41RML100';
  export const TypeEnum = {
    T110: 'T110' as TypeEnum,
    T110IS: 'T110_IS' as TypeEnum,
    S130: 'S130' as TypeEnum,
    S130IS: 'S130_IS' as TypeEnum,
    M140: 'M140' as TypeEnum,
    M140IS: 'M140_IS' as TypeEnum,
    COM41RCS100: 'COM_41RCS100' as TypeEnum,
    COM41RCS100IS: 'COM_41RCS100_IS' as TypeEnum,
    COMNONE41RFU100: 'COM_NONE_41RFU100' as TypeEnum,
    COM41RFU100: 'COM_41RFU100' as TypeEnum,
    COMNONE41RML100: 'COM_NONE_41RML100' as TypeEnum,
    COM41RML100: 'COM_41RML100' as TypeEnum
  };
}

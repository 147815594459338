<div class="card-table mt-3">
  <div class="card-header flex-card-header">
    <h2>{{ 'APARTMENT.APARTMENT' | translate }}</h2>
    <div class="button-box">
      <button
        *ngIf="
          ![
            'BUILDINGMANAGER-COLLABORATOR',
            'MAINTAINER',
            'BUILDINGMANAGER'
          ].includes(userRole) && canAddApt()
        "
        id="add-apartment"
        (click)="createNewApt()"
        class="button-secondary mr-3"
      >
        <i class="material-icons">add</i
        >{{ 'APARTMENT.ADD_APARTMENT' | translate }}
      </button>
      <button
        id="send-email"
        class="button-secondary mr-3"
        (click)="sendEmailAllSelectedDevices()"
      >
        <i class="material-icons">email</i
        >{{ 'APARTMENT.SEND_EMAIL' | translate }}
      </button>
      <app-search-input class="mr-0 ml-auto"></app-search-input>
    </div>
  </div>
  <div class="card-body">
    <app-mobile-table
      *ngIf="isMobile"
      [hidePagination]="isLoading"
      [dataSource]="apartmentList"
    >
      <div
        id="mobile-apartments"
        class="mobile-table"
        (scroll)="mobileService.scrollManagement($event)"
      >
        <div
          *ngFor="let item of apartmentList.items; let i = index"
          class="mobile-card apartment"
          (click)="selectApt({ selected: [item] })"
        >
          <ng-container
            *ngTemplateOutlet="
              enableTpl;
              context: { value: item.enable, rowIndex: i }
            "
          ></ng-container
          ><span id="vipaddr-{{ i }}" class="mobile-card-title">{{
            isSbc(item.deviceModel)
              ? item.vipaddr.split('SBVIP')[1]
              : item.vipaddr
          }}</span>
          <span
            *ngIf="item.descr"
            id="descr-{{ i }}"
            class="mobile-card-subtitle"
            >{{ item.descr }}</span
          >
          <span class="mobile-card-note"
            ><ng-container
              *ngTemplateOutlet="
                licenseTpl;
                context: { value: item.lictype, rowIndex: i, row: item }
              "
            ></ng-container>
            -
            <ng-container
              *ngTemplateOutlet="deviceTpl; context: { row: item, rowIndex: i }"
            ></ng-container
          ></span>
          <ng-container
            *ngTemplateOutlet="menuTpl; context: { row: item, rowIndex: i }"
          ></ng-container>
        </div>
      </div>
    </app-mobile-table>

    <app-datatable
      *ngIf="!isMobile"
      [columns]="columns"
      [dataSource]="apartmentList"
      [hidePagination]="isLoading"
      selectionType="single"
      (elSelected)="selectApt($event)"
      [messages]="{
        emptyMessage: isLoading ? '' : ('DATATABLE.NO_APARTMENTS' | translate)
      }"
    ></app-datatable>
    <app-loading
      [isLoading]="isLoading"
      [label]="'APARTMENT.DOWNLOAD_IN_PROCESS' | translate"
    ></app-loading>
    <ng-container *ngIf="!isLoading && !apartmentList">
      <b>{{ 'APARTMENT.SELECT_DEVICE_TO_SEE_APT' | translate }}</b>
    </ng-container>
  </div>
</div>

<ng-template #indexTpl let-row="row" let-rowIndex="rowIndex">
  <span id="id-{{ rowIndex }}">
    {{ row.id + 1 }}
  </span>
</ng-template>
<ng-template #nameTpl let-row="row" let-rowIndex="rowIndex">
  <span id="vipaddr-{{ rowIndex }}">
    {{
      isSbc(row.deviceModel) ? row.vipaddr.split('SBVIP')[1] : row.vipaddr
    }}</span
  >
</ng-template>
<ng-template
  #licenseTpl
  let-value="value"
  let-rowIndex="rowIndex"
  let-row="row"
>
  <span id="license-{{ rowIndex }}">{{
    getLicense(value, row.deviceModel)
  }}</span>
</ng-template>
<ng-template #addressBookTpl let-value="value" let-rowIndex="rowIndex">
  <span id="address-book-{{ rowIndex }}"> {{ getAddressBook(value) }}</span>
</ng-template>
<ng-template #deviceTpl let-row="row" let-rowIndex="rowIndex">
  <b id="device-{{ rowIndex }}">{{ row.deviceLabel }}</b>
</ng-template>

<ng-template #enableTpl let-value="value" let-rowIndex="rowIndex">
  <span
    id="abilitation-{{ rowIndex }}"
    class="abilitation"
    [ngClass]="{ green: value }"
  ></span>
</ng-template>

<ng-template #menuTpl let-row="row" let-rowIndex="rowIndex">
  <div class="btn-group" dropdown container="body">
    <i
      id="menu-{{ rowIndex }}"
      class="material-icons menu-icon"
      dropdownToggle
      (click)="$event.stopPropagation()"
      >more_vert</i
    >
    <ul
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right"
      role="menu"
      aria-labelledby="menu"
    >
      <li role="menuitem">
        <a
          id="view-{{ rowIndex }}"
          class="dropdown-item"
          [routerLink]="['/sites', siteId, 'apartments', row.deviceId, row.id]"
          >{{ 'GLOBAL.VIEW' | translate }}</a
        >
      </li>
      <li role="menuitem">
        <a
          id="reset-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="resetApt(row)"
          >{{ 'GLOBAL.RESET' | translate }}</a
        >
      </li>
      <li role="menuitem">
        <a
          id="disable-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="disableApt(row)"
          >{{
            (row.enable ? 'GLOBAL.DISABLE' : 'GLOBAL.ENABLE') | translate
          }}</a
        >
      </li>
      <li role="menuitem">
        <a
          *ngIf="
            ![
              'BUILDINGMANAGER-COLLABORATOR',
              'MAINTAINER',
              'BUILDINGMANAGER'
            ].includes(userRole)
          "
          id="delete-{{ rowIndex }}"
          href="javascript:void(0)"
          class="dropdown-item"
          (click)="deleteApt(row)"
          >{{ 'GLOBAL.DELETE' | translate }}</a
        >
      </li>
    </ul>
  </div>
</ng-template>

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SetDeviceStateDto {
  /**
   * Loop index is an integer value containing the index of the loop the device belongs to in loops list
   */
  loopIndex: number;
  /**
   * Address is an integer value containing the index of device inside its loop
   */
  address: number;
  /**
   * Contains the the state the device must assume
   */
  deviceState: SetDeviceStateDto.DeviceStateEnum;
}
export namespace SetDeviceStateDto {
  export type DeviceStateEnum = 'RESET_OR_DEACTIVATE' | 'SET_OR_ACTIVATE';
  export const DeviceStateEnum = {
    RESETORDEACTIVATE: 'RESET_OR_DEACTIVATE' as DeviceStateEnum,
    SETORACTIVATE: 'SET_OR_ACTIVATE' as DeviceStateEnum
  };
}

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status1 of device.
 */
export interface DeviceStatus1BitMaskDto {
  disabled?: boolean;
  fault?: boolean;
  fire?: boolean;
  prefire?: boolean;
  dayNightMode?: boolean;
  blinkEnabled?: boolean;
  on?: boolean;
  outputCh1On?: boolean;
  outputCh2On?: boolean;
  outputCh3On?: boolean;
  outputCh4On?: boolean;
  activated?: boolean;
  inputCh1Activated?: boolean;
  inputCh2Activated?: boolean;
  inputCh3Activated?: boolean;
  inputCh4Activated?: boolean;
}

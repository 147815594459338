import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  title: string;
  description: string;

  confirmAction = new EventEmitter<void>();
  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {}
}

/**
 * DPCP Fire Communication API
 * API to interact with dpcp fire devices
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Detector status.
 */
export interface DetectorStatusBitMaskDto {
  disabled?: boolean;
  fault?: boolean;
  fire?: boolean;
  preFire?: boolean;
  dayNightMode?: boolean;
  blinkEnabled?: boolean;
  /**
   * true if the zone the device belongs to is in test
   */
  test?: boolean;
  isolated?: boolean;
  chamberFault?: boolean;
  maintenance?: boolean;
}
